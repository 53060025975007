import { Component, Input, Output, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-ui-date-day',
  templateUrl: './date-day.component.html',
  styleUrls: ['./date-day.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class PolytechUiDateDayComponent {
  @Input() day: number;
  @Input() timestamp: number;
  @Input() earliest = 0;
  @Input() latest = Infinity;
  @Output() clickSelect: EventEmitter<number> = new EventEmitter<number>();

  inRange() {
    return this.timestamp >= this.earliest && this.timestamp <= this.latest;
  }

  clickHandler(event: MouseEvent) {
    event.stopPropagation();
    this.clickSelect.emit(this.day);
  }
}
