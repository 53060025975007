import { Component } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule],
})
export class CallbackComponent {
  constructor(private authService: AuthenticationService, private router: Router) {}

  redirectSubscription = combineLatest([
    this.authService.getRedirectPath(),
    this.authService.getUserAccessPermissions(),
  ])
    .pipe(
      filter(([, canAccess]) => canAccess.length > 0),
      take(1)
    )
    .subscribe({
      next: ([redirectPath, canAccess]) => {
        if (redirectPath) {
          void this.router.navigateByUrl(redirectPath);
        } else {
          void this.router.navigateByUrl(this.homeRoute(canAccess));
        }
        this.authService.updateRedirectPath(undefined);
      },
    });

  homeRoute(canAccess: string[]): string {
    if (!canAccess.includes('web_app')) {
      return '/forbidden';
    }

    if (canAccess.includes('components')) {
      if (canAccess.includes('utilization')) {
        return '/components/utilization';
      } else {
        return '/components/humidity';
      }
    }

    if (canAccess.includes('projects')) {
      if (canAccess.includes('utilization')) {
        return '/projects/utilization';
      } else {
        return '/projects/humidity';
      }
    }
    return '/forbidden';
  }
}
