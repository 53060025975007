<mat-card class="info-card">
  <!--  <mat-card-header fxLayout="row">
    <mat-card-title class="title">{{
      translateText("PROJECT_COMPONENTS")
    }}</mat-card-title>
  </mat-card-header> -->
  <mat-card-content fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="16px">
    <div
      *ngFor="let button of buttons; index as index"
      (click)="button.count !== 0 && clickHandler(button)"
      [ngClass]="{ 'btn-filter': true }"
      fxLayout="row"
      fxLayoutGap="16px"
      [class.disabled]="button.count === 0"
    >
      <div class="inner" fxLayout="row" fxLayoutAlign="start">
        <div class="icon-wrapper" fxLayout="column" [class.selected]="button.selected">
          <svg-icon [src]="button.iconSrc" class="icon"></svg-icon>
        </div>
        <div class="count" fxLayout="column">
          <div class="count-label">
            {{ button.labelText | async }}
          </div>
          <div class="count-number">{{ button.count }}</div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
