import { Pipe, PipeTransform } from '@angular/core';
import { formatDate, isDefined } from '../utils';

@Pipe({
  name: 'formatCell',
  standalone: true,
})
export class FormatCellPipe implements PipeTransform {
  transform(cellValue: string | number | Date | undefined | null): string {
    if (!isDefined(cellValue)) {
      return '';
    }

    if (cellValue instanceof Date) {
      return formatDate(cellValue);
    }

    return cellValue?.toString();
  }
}
