import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ComponentsService } from 'src/app/services/api/components.service';

import { map, switchMap } from 'rxjs/operators';
import { SolutionSpace } from '../../models/solution';
import { ComponentsOverviewComponent } from '../../shared/components-overview/components-overview.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-zx-components-overview',
  templateUrl: './zx-components-overview.component.html',
  styleUrls: ['./zx-components-overview.component.scss'],
  standalone: true,
  imports: [NgIf, ComponentsOverviewComponent, AsyncPipe],
})
export class ZxComponentsOverviewComponent implements OnInit {
  links = [
    {
      id: 'overview',
      label: 'component.componentOverviewTab',
      path: 'overview',
    },
    {
      id: 'insights',
      label: 'component.insightsTab',
      path: 'insights',
    },
    {
      id: 'datalog',
      label: 'component.componentDatalogTab',
      path: 'datalog',
    },
    {
      id: 'incidents',
      label: 'component.componentIncidentsTab',
      path: 'incidents',
    },
  ];

  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  componentId$ = this.route.params.pipe(map((el) => el.id as string));

  component$ = combineLatest([this.solutionSpace$, this.componentId$]).pipe(
    switchMap(([solutionSpace, componentId]) => this.componentsService.getComponent(solutionSpace, componentId))
  );

  constructor(private componentsService: ComponentsService, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.solutionSpace$.subscribe({
      next: (solutionSpace) => {
        if (solutionSpace == SolutionSpace.Utilization) {
          this.links.splice(2, 0, {
            id: 'lifeCycle',
            label: 'component.componentLifeCycleTab',
            path: 'lifeCycle',
          });
        }
      },
    });
  }
}
