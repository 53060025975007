/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartDataset, Chart, ChartOptions } from 'chart.js';
import { HistoricalUtilStatistic } from 'src/app/models/historicalUtilStatistic';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgChartsModule } from 'ng2-charts';
import { NgIf } from '@angular/common';

enum availUse {
  AVAILABLE = 'available',
  INUSE = 'In use',
}
@Component({
  selector: 'app-utilization-statistics',
  templateUrl: './utilization-statistics.component.html',
  styleUrls: ['./utilization-statistics.component.scss'],
  standalone: true,
  imports: [NgIf, NgChartsModule, AngularSvgIconModule],
})
export class UtilizationStatisticsComponent implements OnInit {
  @Input()
  data: HistoricalUtilStatistic = { daysAvailable: 0, daysInUse: 0, daysInService: 0, daysInReturn: 0, cycles: 0 };

  datasets: ChartDataset[] = [];
  options: ChartOptions;

  labels: string[] = ['Available', 'In use', 'Return', 'Service'];

  @Input()
  isMobile: boolean;

  doughnutChartType: ChartType = 'doughnut';
  doughnutChartLabelsInUse: string[] = ['In use', 'Available'];
  doughnutChartLabelsAvailable: string[] = ['Available', 'In use'];

  dataSetInUse: ChartDataset[];
  dataSetAvailable: ChartDataset[];
  totalDays: number;
  maxYvalue: number;
  pourcentageDaysInUse: number;
  pourcentageDaysAvailable: number;

  InUseoptions = {
    cutoutPercentage: 80,
    title: { text: availUse.INUSE },
    responsive: true,
    aspectRatio: 1,
    legend: {
      display: false,
    },
  };

  availablityOptions = {
    cutoutPercentage: 80,
    aspectRatio: 1,
    title: { text: availUse.AVAILABLE },
    responsive: true,

    legend: {
      display: false,
    },
  };
  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    this.datasets = [
      {
        data: [this.data.daysAvailable, this.data.daysInUse, this.data.daysInReturn, this.data.daysInService],
        backgroundColor: ['#028767', '#54585A', '#FF6400', '#4682B4'],
        hoverBackgroundColor: ['#028767', '#54585A', '#ff6c00', '#4682B4'],
      },
    ];
    this.maxYvalue = Math.max(...(this.datasets[0].data as number[]));
    this.isMobile = this.responsiveService.isMobile();
    this.setChartOptions();
  }

  setChartOptions() {
    this.options = {
      layout: {
        padding: {
          bottom: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            font: {
              size: 16,
              weight: !this.isMobile ? 'bold' : 'normal',
            },
            color: 'black',
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          grid: { display: false },
          max: this.maxYvalue,
          ticks: {
            stepSize: Math.round(this.maxYvalue / 5),
            color: '#918E85',
          },
        },
      },
    };
  }

  getPourcentage(sum: number, element: number): number {
    if (sum !== 0) {
      return (element / sum) * 100;
    }
    return 0;
  }

  addLabelsInsideChart(data: HistoricalUtilStatistic): void {
    let displayedText = '';
    function getPourcentage(sum: number, element: number): number {
      if (sum !== 0) {
        return (element / sum) * 100;
      }
      return 0;
    }
    let daysCounter = '';
    Chart.register({
      id: 'customPlugin',
      beforeDraw: function (chart) {
        const totalDays = data.daysAvailable + data.daysInUse;
        if (chart.config.options?.plugins?.title?.text === availUse.INUSE) {
          displayedText = Math.round(getPourcentage(totalDays, data.daysInUse)).toString();
          daysCounter = data.daysInUse.toString() + ' Days';
        } else {
          displayedText = Math.round(getPourcentage(totalDays, data.daysAvailable)).toString();
          daysCounter = data.daysAvailable.toString() + ' Days';
        }
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        if (ctx) {
          ctx.restore();
          if (window.matchMedia('(max-width: 1299px)').matches) {
            ctx.font = '400 10px Roboto';
          } else {
            ctx.font = 'bold 36px Roboto';
          }
          ctx.font = 'bold 36px Roboto';
          const text = displayedText,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillStyle = '#000';
          ctx.fillText(text, textX, textY);
          // pourcentage drawing
          if (window.matchMedia('(max-width: 1299px) ').matches) {
            ctx.font = '600 8px Roboto';
          } else {
            ctx.font = '700 16px Roboto';
          }

          ctx.fillStyle = '#666';
          ctx.fillText('%', Math.round(width / 2) + 5 + ctx.measureText(text).width, textY - 10);

          // set up the days number text and style for the chart inside label
          if (window.matchMedia('(max-width: 1299px)').matches) {
            ctx.font = '400 7px Roboto';
          } else {
            ctx.font = '500 15px Roboto';
          }
          ctx.fillStyle = '#666';

          const dayCountX = Math.round((width - ctx.measureText(daysCounter).width) / 2);
          ctx.fillText(daysCounter, dayCountX, textY + 30);
          ctx.save();
        }
      },
    });
  }
}
