import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, NgClass } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AngularSvgIconModule, NgIf, MatButtonModule, NgClass, ExtendedModule],
})
export class CreateDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      componentName: string;
      isMobile: boolean;
    }
  ) {}

  ngOnInit(): void {}

  dialogTitle(): string {
    return `Component ${this.data.componentName} created`;
  }

  isMobile(): boolean {
    return this.data.isMobile;
  }
}
