import { Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [MatButtonModule, NgClass, ExtendedModule, NgIf, AngularSvgIconModule],
})
export class ButtonComponent {
  @Input() buttonText: string | undefined;
  @Input() theme: string | undefined;
  @Input() iconUrl: string | undefined;
  @Input() id?: string;
}
