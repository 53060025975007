import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, forkJoin, map, ReplaySubject, Subject, switchMap } from 'rxjs';
import { IncidentFilter } from 'src/app/models/incident-filter';
import { IncidentsSortOrder, PageRequest } from 'src/app/models/page-request';
import { SolutionSpace } from 'src/app/models/solution';
import { IncidentsService } from 'src/app/services/api/incidents.service';
import { ProjectsService } from 'src/app/services/api/projects.service';
import {
  UpdateIncidentsRequest,
  UpdateIncidentsResponse,
} from 'src/app/shared/component-incidents/component-incidents.component';
import { ComponentIncidentsComponent } from '../../shared/component-incidents/component-incidents.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-zx-project-component-incidents',
  templateUrl: './zx-project-component-incidents.component.html',
  styleUrls: ['./zx-project-component-incidents.component.scss'],
  standalone: true,
  imports: [NgIf, ComponentIncidentsComponent, AsyncPipe],
})
export class ZxProjectComponentIncidentsComponent {
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  projectId$ = this.route.params.pipe(map((el) => el.id as string));
  componentId$ = this.route.params.pipe(map((el) => el.componentId as string));

  filter$ = new ReplaySubject<IncidentFilter>();
  pagination$ = new ReplaySubject<PageRequest<IncidentsSortOrder>>();

  incidents$ = combineLatest([this.projectId$, this.componentId$, this.filter$, this.pagination$]).pipe(
    switchMap(([projectId, componentId, filter, pagination]) =>
      this.projectSerivce.getComponentIncidents(projectId, componentId, filter, pagination)
    )
  );

  updateIncidentsResponse$ = new Subject<UpdateIncidentsResponse>();

  constructor(
    private route: ActivatedRoute,
    private projectSerivce: ProjectsService,
    private incidentService: IncidentsService
  ) {}

  onChangeIncidentsFilter(newFilter: IncidentFilter) {
    this.filter$.next(newFilter);
  }

  onChangePagination(newPagination: PageRequest<IncidentsSortOrder>) {
    this.pagination$.next(newPagination);
  }

  onUpdateIncidents(request: UpdateIncidentsRequest) {
    forkJoin(
      request.incidentIds.map((incidentId) =>
        this.incidentService.updateIncident(incidentId, request.state, request.comment)
      )
    ).subscribe({
      next: () => this.updateIncidentsResponse$.next({ total: request.incidentIds.length, state: request.state }),
    });
  }
}
