import { Component, Input, OnInit } from '@angular/core';
import { ZXComponent } from 'src/app/models/component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SolutionSpace } from '../../models/solution';

import { Breadcrumb } from 'src/app/shared/info-page/info-page.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { InfoPageComponent } from '../info-page/info-page.component';

@Component({
  selector: 'app-components-overview',
  templateUrl: './components-overview.component.html',
  styleUrls: ['./components-overview.component.scss'],
  standalone: true,
  imports: [InfoPageComponent, NgIf, MatButtonModule, RouterLink, MatIconModule],
})
export class ComponentsOverviewComponent implements OnInit {
  @Input() costumButtom?: { text: string; action: () => void; cssStyle?: string; visible: boolean };
  @Input() component: ZXComponent;
  @Input() solutionSpace: SolutionSpace;
  @Input() links: { id: string; label: string; path: string }[];

  breadcrumbs: Breadcrumb[] = [];

  activeLink: string | undefined;
  origin: string;

  constructor(private router: Router) {
    router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((navigationEnd) => navigationEnd.urlAfterRedirects)
      )
      .subscribe((url: string) => {
        this.activeLink = url.split('?')[0].split('/').pop(); // todo unit test
        this.setBreadcrumbs();
      });
  }
  ngOnInit(): void {
    this.setBreadcrumbs();
    this.origin = this.getOrigin();
  }

  getOrigin(): string {
    return document.location.pathname;
  }

  setBreadcrumbs() {
    const crumbs = [
      { link: `/components/${this.solutionSpace}`, label: 'Components' },
      { link: './overview', label: this.component ? this.component.name : '' },
    ];

    if (this.activeLink) {
      const label = this.activeLink.charAt(0).toUpperCase() + this.activeLink.slice(1);

      switch (this.activeLink) {
        case 'overview':
          break;
        case 'lifeCycle':
          crumbs.push({ link: `./${this.activeLink}`, label: 'Turns monitering' });
          break;
        default:
          crumbs.push({ link: `./${this.activeLink}`, label: label });
      }
    }
    this.breadcrumbs = crumbs;
  }
}
