/* eslint-disable @typescript-eslint/unbound-method */
import { Component } from '@angular/core';
import { ZxComponentFormComponent } from '../zx-component-form/zx-component-form.component';

@Component({
  selector: 'app-zx-component-create',
  templateUrl: './zx-component-create.component.html',
  styleUrls: ['./zx-component-create.component.scss'],
  standalone: true,
  imports: [ZxComponentFormComponent],
})
export class ZxComponentCreateComponent {
  constructor() {}
}
