import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-multi-select-field',
  templateUrl: './multi-select-field.component.html',
  styleUrls: ['./multi-select-field.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, NgIf, MatSelectModule, FormsModule, ReactiveFormsModule, NgFor, MatOptionModule],
})
export class MultiSelectFieldComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<UntypedFormControl>();
  @Input() optionArray: { value: string; label: string }[];
  @Input() defaultChoice: string[] | undefined;
  @Input() disabled = false;
  @Input() label?: string;

  formControl: UntypedFormControl;

  constructor() {}

  setValue(selected: string[]): void {
    this.formControl.setValue(selected);
  }

  identify(index: number, item: { value: string; label: string }): string {
    return item.value;
  }

  updateFormControl(): void {
    if (this.defaultChoice && this.defaultChoice.length === 1 && this.defaultChoice[0] === '_all_') {
      this.formControl = new UntypedFormControl(this.optionArray.map((o) => o.value));
    } else {
      this.formControl = new UntypedFormControl(this.defaultChoice);
    }
  }

  ngOnInit(): void {
    this.updateFormControl();
  }

  ngOnChanges(): void {
    this.updateFormControl();
  }

  notify(): void {
    this.selectionChange.emit(this.formControl);
  }
}

// https://dev.insights.polytech.com/assets/images/iconCardsAvailabilityInUse.svg
