import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DateInterval } from 'src/app/models/date.model';
import { FilterUpdate } from 'src/app/zx-components/zx-components-list/zx-components.component';
import { FilterComponent, FilterOption } from './filter/filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgIf, NgClass } from '@angular/common';

export enum ExportType {
  FE_LIST,
  BE_DATA,
}

@Component({
  selector: 'app-components-menu',
  templateUrl: './components-menu.component.html',
  styleUrls: ['./components-menu.component.scss'],
  standalone: true,
  imports: [FilterComponent, NgIf, AngularSvgIconModule, NgClass, ExtendedModule, MatMenuModule, TranslateModule],
})
export class ComponentsMenuComponent {
  @ViewChild(FilterComponent) filterComponent: FilterComponent;

  @Input() canSwapToMap = false;
  @Input() filterOptions: FilterOption[];
  @Input() isProjectComponentlist = false;
  @Input() projects: { value: string; label: string; isSelected?: boolean }[] = [];
  @Input() selectedRows = 0;
  @Input() shouldExport = true;
  @Input() types: { value: string; label: string; isSelected?: boolean }[] = [];
  @Output() archiveComponents = new EventEmitter();
  @Output() exportComponents = new EventEmitter();
  @Output() projectDialogTrigger = new EventEmitter();
  @Output() switchView = new EventEmitter();
  @Output() unselectTrigger = new EventEmitter();
  @Output() updateData: EventEmitter<FilterUpdate> = new EventEmitter<FilterUpdate>();

  archived: boolean | undefined = false;
  exportType: typeof ExportType = ExportType;
  lastCommuncatedDateRange: DateInterval | undefined = undefined;
  showMap = false;

  deselect() {
    this.selectedRows = 0;
    this.unselectTrigger.emit();
  }

  onArchive() {
    this.archiveComponents.emit();
  }

  onExport(type: ExportType) {
    this.exportComponents.emit(type);
  }

  onProjectClick() {
    this.projectDialogTrigger.emit();
  }

  onShowMapClick() {
    this.showMap = !this.showMap;
    this.switchView.emit(this.showMap);
  }
}
