import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, NgFor, MatOptionModule],
})
export class SelectFieldComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<UntypedFormControl>();
  @Input() optionArray: { value: string; label: string }[];
  @Input() defaultChoice: string | undefined;
  @Input() disabled = false;

  formControl: UntypedFormControl;

  constructor() {}

  setValue(search: string): void {
    this.formControl.setValue(search);
  }

  identify(index: number, item: { value: string; label: string }): string {
    return item.value;
  }

  ngOnInit(): void {
    this.formControl = new UntypedFormControl(this.defaultChoice);
  }

  /* ngOnChanges(): void {
   // this.formControl = new FormControl(this.defaultChoice);
    // TODO figure out why this is done... this resets the select everytime something is selected that does not fit the data @see Spec 894
  } */

  notify(): void {
    this.selectionChange.emit(this.formControl);
  }
}
