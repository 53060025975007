import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Device } from 'src/app/models/device';
import { deviceTableRow } from 'src/app/models/transforms';
import { SolutionSpace } from 'src/app/models/solution';
import { DevicesService } from 'src/app/services/api/devices.service';
import { UserService } from 'src/app/services/api/user.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { DevicesSortOrder, SortDirection } from 'src/app/models/page-request';
import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from '../../shared/table/table.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { SearchFieldComponent } from '../../shared/search-field/search-field.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatButtonModule,
    MatMenuModule,
    AngularSvgIconModule,
    NgFor,
    SearchFieldComponent,
    MatCardModule,
    NgIf,
    MatProgressSpinnerModule,
    TableComponent,
    TranslateModule,
  ],
})
export class DevicesComponent implements OnInit {
  privateparamSubscription: Subscription;
  solutionSpace: SolutionSpace;
  privaterouteSub: Subscription | undefined;
  devices: TableRow[];
  groups: Array<string> = [];
  types: string[];
  isLoaded = false;
  pageSize = 50;
  totalItemCount = 0;
  resultCount = 0;
  pageNumber = 1;

  // search
  searchText: string | undefined;
  // type
  deviceTypes: { value: string; label: string; icon: string }[] = [
    { value: '_all_', label: 'All', icon: '/assets/icons/filter.svg' },
    { value: '0', label: 'Logistics Hub Utilization', icon: '/assets/icons/utilization-device.svg' },
    { value: '1', label: 'Logistics Hub Humidity', icon: '/assets/icons/icon-16-humidity.svg' },
  ];
  deviceType: string | undefined;
  displayedFilter = { label: '', icon: '' };
  sortBy = DevicesSortOrder.LAST_COMMUNICATED;
  displayedColumns: TableColumn[] = [
    { key: 'name', name: 'Device ID', isSortable: true },
    { key: 'componentName', name: 'Component name' },
    { key: 'type', name: 'Device type' },
    { key: 'firmware', name: 'FW Version', isSortable: true },
    { key: 'lastCommunicationTime', name: 'Last communicated', isSortable: true },

    //{ key: 'FWVersion', name: 'FW Version' },
    // { key: 'batteryLevel', name: 'Battery' },
    // { key: 'subscriptionPeriod', name: 'Subscription period' },
    // { key: 'group', name: 'Group' },
    // { key: 'action', name: '' },
  ];
  sortDirection: SortDirection = SortDirection.DESC;

  constructor(
    private devicesService: DevicesService,
    private userService: UserService,
    private responseHandlerService: ResponseHandlerService,
    private titleService: Title,
    private responsiveService: ResponsiveService
  ) {
    this.displayedFilter = { label: this.deviceTypes[0].label, icon: '/assets/icons/filter.svg' };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Device Pool - Admin');
    this.userService.getGroupsForComponentCreation().subscribe({
      next: (result) => {
        this.groups = result.map((group) => group.id);
        this.devicesService
          .getAllDevices(
            {
              groupIds: this.groups,
            },
            {
              pageNumber: this.pageNumber,
              pageSize: this.pageSize,
              sortOrder: DevicesSortOrder.LAST_COMMUNICATED,
              sortDirection: SortDirection.DESC,
            }
          )

          .subscribe((devicesPageable) => {
            this.totalItemCount = devicesPageable.totalItemCount;
            this.resultCount = devicesPageable.totalItemCount;
            if (devicesPageable.items && devicesPageable.items.length > 0) {
              this.devices = devicesPageable.items.map((d) => deviceTableRow(d));
            }

            this.isLoaded = true;
          });
      },
      error: (error: HttpErrorResponse) => {
        this.responseHandlerService.error(error);
      },
    });
  }

  setSearchControl(search: string): void {
    this.searchText = search.replace(/:/g, '-');
    this.loadDevices();
  }
  setDeviceTypeControl(deviceTypeControl: { label: string; value: string; icon: string }): void {
    this.deviceType = deviceTypeControl.value;
    this.displayedFilter.label = deviceTypeControl.label;
    this.displayedFilter.icon = deviceTypeControl.icon;
    this.loadDevices();
  }

  isMobile(): boolean {
    return this.responsiveService.isMobile();
  }

  setSorting(event: Sort): void {
    this.sortDirection = event.direction == 'asc' ? SortDirection.ASC : SortDirection.DESC;
    switch (event.active) {
      case 'name': {
        this.sortBy = DevicesSortOrder.IDENTIFIER;
        break;
      }
      case 'firmware': {
        this.sortBy = DevicesSortOrder.FIRMWARE;
        break;
      }
      case 'lastCommunicationTime': {
        this.sortBy = DevicesSortOrder.LAST_COMMUNICATED;
        break;
      }
    }
    this.loadDevices();
  }

  loadDevices(): void {
    const solution: SolutionSpace | undefined =
      this.deviceType === '0'
        ? SolutionSpace.Utilization
        : this.deviceType === '1'
        ? SolutionSpace.Humidity
        : undefined;
    // call API for filtering
    this.isLoaded = false;

    this.devicesService
      .getAllDevices(
        {
          groupIds: this.groups,
          solutionSpace: solution,
          search: this.searchText,
        },
        {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          sortOrder: this.sortBy,
          sortDirection: this.sortDirection,
        }
      )
      .subscribe({
        next: (devicesPageable) => {
          this.totalItemCount = devicesPageable.totalItemCount;
          this.resultCount = devicesPageable.totalItemCount;
          this.devices = devicesPageable.items.map((d) => deviceTableRow(d));

          this.isLoaded = true;
        },
      });
  }

  handlePaginationEvent(event: PageEvent): void {
    const { pageIndex, pageSize } = event;
    if (this.pageNumber === pageIndex && this.pageSize === pageSize) return;

    if (this.pageNumber !== pageIndex) {
      this.pageNumber = pageIndex;
    }
    if (this.pageSize !== pageSize) {
      this.pageSize = pageSize;
    }
    this.loadDevices();
  }
}
