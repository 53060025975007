import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, map, switchMap, take } from 'rxjs';
import { BackendComponentActivityDescription } from 'src/app/models/backend/backend-component-activity';
import { SolutionSpace } from 'src/app/models/solution';
import { ComponentsService } from 'src/app/services/api/components.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { ActivityType } from 'src/app/shared/recent-history/recent-history.component';
import { formatDate } from 'src/app/shared/utils';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

export interface Journey {
  type: ActivityType;
  startDate: Date;
  endDate: Date;
  showTooltip: boolean;
}
interface Cycle {
  startDate: string;
  endDate: string;
  journey: Array<Journey>;
  isCompleted: boolean;
  totalCycleDays: number;
}

const descriptions: Array<BackendComponentActivityDescription> = [
  BackendComponentActivityDescription.Created_By_Username,
  BackendComponentActivityDescription.Unloaded,
  BackendComponentActivityDescription.Loaded,
  BackendComponentActivityDescription.Received_In_Service,
  BackendComponentActivityDescription.Released_From_Service,
];

@Component({
  selector: 'app-zx-component-life-cycle',
  templateUrl: './zx-component-life-cycle.component.html',
  styleUrls: ['./zx-component-life-cycle.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    NgIf,
    NgFor,
    NgClass,
    ExtendedModule,
    AngularSvgIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatButtonModule,
  ],
})
export class ZxComponentLifeCycleComponent implements OnInit {
  componentId$ = this.route.params.pipe(map((el) => el.id as string));
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );
  lastCommunicatedTime$ = combineLatest([this.solutionSpace$, this.componentId$]).pipe(
    switchMap(([solutionSpace, componentId]) => this.componentsService.getComponent(solutionSpace, componentId)),
    map((component) => new Date(component.componentHealth.lastCommunication))
  );

  loadActivitiesTrigger$ = new BehaviorSubject(null);
  activities$ = combineLatest([this.componentId$, this.loadActivitiesTrigger$]).pipe(
    switchMap(([componentId]) => this.componentsService.getComponentActivity(componentId, 100, descriptions)),
    map((activities) =>
      activities
        .map((activity) => {
          return {
            timestamp: activity.occurredAt,
            activity: activity.getActivityType(),
          };
        })
        .reverse()
    )
  );

  cycles$ = combineLatest([this.activities$, this.lastCommunicatedTime$]).pipe(
    map(([activities, lastCommunicatedTime]) => this.prepareCycles(activities, lastCommunicatedTime))
  );

  cycles: Array<Cycle> = [];
  isLoaded = false;

  constructor(
    private componentsService: ComponentsService,
    private route: ActivatedRoute,
    private responseHandler: ResponseHandlerService
  ) {}

  ngOnInit(): void {
    this.cycles$.subscribe({
      next: (cycles) => {
        this.isLoaded = true;
        this.cycles = cycles;
      },
      error: (error: HttpErrorResponse) => this.responseHandler.error(error),
    });
  }

  prepareCycles(activities: Array<{ timestamp: Date; activity: ActivityType }>, lastCommunicatedTime: Date): Cycle[] {
    const cycles: Cycle[] = [];
    const indexsOfCycle: Array<number> = [];
    for (let i = 0; i < activities.length; i++) {
      const act = activities[i];
      if (act.activity === ActivityType.Created || act.activity === ActivityType.inService) {
        indexsOfCycle.push(i);
      }
    }
    if (indexsOfCycle.length === 1) {
      // created advent
      const indexCycle = indexsOfCycle[0];
      const activityLength = activities.length;
      const journey: Array<Journey> = activities.map((act, index) => {
        return {
          type: act.activity,
          startDate: act.timestamp,
          endDate: activities[index + 1] ? activities[index + 1].timestamp : act.timestamp,
          showTooltip: true,
        };
      });
      const isCompleted = activities[activityLength - 1].activity === ActivityType.inService ? true : false;
      const cycle = this.prepareCycle(
        journey,
        activities[indexCycle].timestamp,
        activities[activityLength - 1].timestamp,
        isCompleted,
        lastCommunicatedTime
      );
      cycles.push(cycle);
      return cycles;
    } else {
      // more than one cycle is detected !!

      for (let j = 0; j < indexsOfCycle.length; j++) {
        if (j + 1 < indexsOfCycle.length) {
          const act = activities.slice(indexsOfCycle[j], indexsOfCycle[j + 1] + 1);
          const journey: Array<Journey> = act.map((a, index) => {
            return {
              type: a.activity,
              startDate: a.timestamp,
              endDate: act[index + 1] ? act[index + 1].timestamp : a.timestamp,
              showTooltip: true,
            };
          });
          const isCompleted = activities[indexsOfCycle[j + 1]].activity === ActivityType.inService ? true : false;
          const cycle = this.prepareCycle(
            journey,
            activities[indexsOfCycle[j]].timestamp,
            activities[indexsOfCycle[j + 1]].timestamp,
            isCompleted,
            lastCommunicatedTime
          );
          cycles.push(cycle);
        }
      }
      const cyclesLength = indexsOfCycle.length - 1;
      // the rest of the cycle is considered new cycle ecven if it's not finished, it should be added !!
      if (indexsOfCycle[cyclesLength] < activities.length - 1) {
        const act = activities.slice(indexsOfCycle[cyclesLength], activities.length);

        const journey: Array<Journey> = act.map((a, index) => {
          return {
            type: a.activity,
            startDate: a.timestamp,
            endDate: act[index + 1] ? act[index + 1].timestamp : a.timestamp,
            showTooltip: true,
          };
        });
        const isCompleted = activities[activities.length - 1].activity === ActivityType.inService ? true : false;
        const cycle = this.prepareCycle(
          journey,
          activities[indexsOfCycle[cyclesLength]].timestamp,
          activities[activities.length - 1].timestamp,
          isCompleted,
          lastCommunicatedTime
        );
        cycles.push(cycle);
      } else if (activities[indexsOfCycle[cyclesLength]].activity === ActivityType.inService) {
        // createe another cycle with just inservice journey to allow releasing it in the next cycle

        const journey = [
          {
            type: activities[indexsOfCycle[cyclesLength]].activity,
            startDate: activities[indexsOfCycle[cyclesLength]].timestamp,
            endDate: activities[indexsOfCycle[cyclesLength]].timestamp,
            showTooltip: false,
          },
        ];
        const isCompleted = activities[activities.length - 1].activity === ActivityType.inService ? true : false;
        const cycle = this.prepareCycle(
          journey,
          activities[indexsOfCycle[cyclesLength]].timestamp,
          activities[activities.length - 1].timestamp,
          isCompleted,
          lastCommunicatedTime
        );

        cycles.push(cycle);
      }
      return cycles;
    }
  }

  displayTooltipText(journey: Journey): string {
    const text = `${journey.startDate.toLocaleDateString()} - ${journey.endDate.toLocaleDateString()}
    ${this.getDaysDifferencebetweenTwoDays(journey.endDate, journey.startDate)}  Days ${this.getJourneyDesc(
      journey.type
    )}`;
    return text;
  }

  calculateJourneyWidth(endDate: Date, startDate: Date, totalCycleDuration: number): number | string {
    const journeyDuration = this.getDaysDifferencebetweenTwoDays(endDate, startDate);
    return journeyDuration / totalCycleDuration !== 0 ? (journeyDuration / totalCycleDuration) * 100 : 'unset';
  }

  prepareCycle(
    journeys: Array<Journey>,
    startDate: Date,
    endDate: Date,
    isCompleted: boolean,
    lastCommunicatedTime: Date
  ): Cycle {
    const cycle = {
      journey: journeys,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      isCompleted: isCompleted,
      totalCycleDays: this.getDaysDifferencebetweenTwoDays(endDate, startDate),
    };

    // disable tooltip for inservice last journey
    const lastElementInJourney = cycle.journey.length - 1;
    if (cycle.isCompleted) {
      if (cycle.journey[lastElementInJourney].type === ActivityType.inService) {
        cycle.journey[lastElementInJourney].showTooltip = false;
      }
    } else {
      // cycle is not completed , the last journey end date should be last communicated date
      if (cycle.journey[lastElementInJourney].type !== ActivityType.inService) {
        cycle.journey[lastElementInJourney].endDate = lastCommunicatedTime;
        cycle.endDate = formatDate(lastCommunicatedTime);
      }
    }
    cycle.totalCycleDays = cycle.journey.reduce(
      (sum, journey) => sum + this.getDaysDifferencebetweenTwoDays(journey.endDate, journey.startDate),
      0
    );
    return cycle;
  }

  getJourneyIcon(activity: ActivityType): string {
    switch (activity) {
      case ActivityType.Created:
        return '/assets/icons/journey/active.svg';
      case ActivityType.Available:
        return '/assets/icons/journey/active.svg';
      case ActivityType.InUse:
        return '/assets/icons/journey/inUse.svg';
      case ActivityType.inReturn:
        return '/assets/icons/journey/inReturn.svg';
      case ActivityType.inService:
        return '/assets/icons/journey/service.svg';
      default:
        return '<error>';
    }
  }

  getJourneyDesc(activity: ActivityType): string {
    switch (activity) {
      case ActivityType.Created:
        return 'Available';
      case ActivityType.Available:
        return 'Available';
      case ActivityType.InUse:
        return 'In Use';
      case ActivityType.inReturn:
        return 'In Return';
      case ActivityType.inService:
        return 'In Service';
      default:
        return '<error>';
    }
  }

  setStateToReceived() {
    this.isLoaded = false;
    this.componentId$
      .pipe(
        switchMap((componentId) => this.componentsService.receivedInService(componentId)),
        take(1)
      )
      .subscribe({ next: () => this.loadActivitiesTrigger$.next(null) });
  }

  getDaysDifferencebetweenTwoDays(endDate: Date, startDate: Date): number {
    const difference = endDate.getTime() - startDate.getTime();
    const totalDays = Math.floor(difference / (1000 * 3600 * 24));
    return totalDays > 0 ? totalDays : 0;
  }

  setStateToReleasedFromService() {
    this.isLoaded = false;
    this.componentId$
      .pipe(
        switchMap((componentId) => this.componentsService.ReleaseFromService(componentId)),
        take(1)
      )
      .subscribe({ next: () => this.loadActivitiesTrigger$.next(null) });
  }
}
