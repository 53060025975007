import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
  standalone: true,
  imports: [MatIconModule, AngularSvgIconModule],
})
export class FilterOptionComponent {
  @Input() isDisabled: boolean;
  @Input() icon: string;
  @Input() title: string;
  @Output() apply: EventEmitter<void> = new EventEmitter<void>();
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  onApply() {
    this.apply.emit();
  }

  onBack(event: Event) {
    event.stopPropagation();
    this.back.emit();
  }
}
