/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SolutionSpace } from 'src/app/models/solution';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface ComponentTypeDiaglog {
  solution: SolutionSpace;
  name?: string;
  description?: string;
  distanceRangeLow?: number;
  distanceRangeHigh?: number;
  isEdit: boolean;
  humidityThreshold?: number;
  isHumidityWarningActive: boolean;
  shockThreshold?: number;
  isShockWarningActive: boolean;
}

@Component({
  selector: 'app-type-dialog',
  templateUrl: './type-dialog.component.html',
  styleUrls: ['./type-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    AngularSvgIconModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatButtonModule,
    NgStyle,
    ExtendedModule,
  ],
})
export class TypeDialogComponent implements OnInit {
  createClicked = false;
  deleteClicked = false;
  shockValues: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  componentTypeForm = this.fb.group({
    name: [this.data.name, Validators.required],
    description: [this.data.description],
    mindistance: [this.data.distanceRangeLow?.toString(), Validators.required],
    maxdistance: [this.data.distanceRangeHigh?.toString(), Validators.required],
    humidityThreshold: [this.data.humidityThreshold?.toString()],
    isHumidityWarningActive: [this.data.isHumidityWarningActive],
    shockThreshold: [this.data.shockThreshold?.toString()],
    isShockWarningActive: [this.data.isShockWarningActive],
  });
  min = 130;
  max = 800;
  interval = 10;
  solution = this.data.solution;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ComponentTypeDiaglog, private fb: FormBuilder) {}

  get minValues(): number[] {
    const v = [];
    for (let i = this.min; i <= this.max - this.interval; i += this.interval) {
      v.push(i);
    }
    return v;
  }

  get maxValues(): number[] {
    const v = [];
    for (let i = this.min + this.interval; i <= this.max; i += this.interval) {
      v.push(i);
    }
    return v;
  }

  ngOnInit(): void {}

  invalidForm(): boolean {
    let invalid = this.componentTypeForm.controls['name'].invalid;
    if (!this.isHumidity) {
      invalid =
        invalid ||
        this.componentTypeForm.controls['maxdistance'].invalid ||
        this.componentTypeForm.controls['mindistance'].invalid;
    }
    return invalid;
  }

  get minVal(): number {
    return parseInt(this.componentTypeForm.get('mindistance')?.value as string, 10);
  }

  get maxVal(): number {
    return parseInt(this.componentTypeForm.get('maxdistance')?.value as string, 10);
  }

  minChange(): void {
    const minIndex = this.minValues.indexOf(this.minVal);
    const maxIndex = this.maxValues.indexOf(this.maxVal);
    if (isNaN(this.maxVal) || minIndex > maxIndex) {
      this.componentTypeForm.get('maxdistance')?.setValue(this.maxValues[minIndex].toString());
    }
  }

  maxChange(): void {
    const minIndex = this.minValues.indexOf(this.minVal);
    const maxIndex = this.maxValues.indexOf(this.maxVal);
    if (isNaN(this.minVal) || minIndex > maxIndex) {
      this.componentTypeForm.get('mindistance')?.setValue(this.minValues[maxIndex].toString());
    }
  }

  onClick(): void {
    if (this.createClicked) return;
    this.createClicked = true;
    this.updateDialogDatawithFormValue();
  }

  private updateDialogDatawithFormValue() {
    this.data.name = this.componentTypeForm.value['name'] ?? '';

    if (this.componentTypeForm.value['mindistance'] !== null && this.componentTypeForm.value['mindistance'] !== null) {
      this.data.distanceRangeLow = parseInt(this.componentTypeForm.value['mindistance'] ?? '', 10);
      this.data.distanceRangeHigh = parseInt(this.componentTypeForm.value['maxdistance'] ?? '', 10);
    }
    this.data.description = this.componentTypeForm.value['description'] ?? undefined;

    if (this.componentTypeForm.value['humidityThreshold'] !== null) {
      this.data.humidityThreshold = parseInt(this.componentTypeForm.value['humidityThreshold'] ?? '', 10);
    }

    this.data.isHumidityWarningActive = this.componentTypeForm.value['isHumidityWarningActive'] ?? false;

    if (this.componentTypeForm.value['shockThreshold'] !== null) {
      this.data.shockThreshold = parseInt(this.componentTypeForm.value['shockThreshold'] ?? '', 10);
    }

    this.data.isShockWarningActive = this.componentTypeForm.value['isShockWarningActive'] ?? false;
  }

  deleteDisabled(): boolean {
    return false;
  }

  get isHumidity(): boolean {
    return this.data.solution === SolutionSpace.Humidity;
  }

  get shockWarningValue(): number {
    return parseInt(this.componentTypeForm.get('shockThreshold')?.value as string, 10);
  }

  onShockValueChange(): void {
    this.componentTypeForm.get('shockThreshold')?.setValue(this.shockWarningValue.toString());
  }
}
