import { Organisation } from 'src/app/models/organisation';
import { createStore, select, withProps } from '@ngneat/elf';
import { map } from 'rxjs';
import { persistState, localStorageStrategy, excludeKeys } from '@ngneat/elf-persist-state';

interface AuthProps {
  organisation?: Organisation;
  redirectPath?: string;
}

const authStore = createStore(
  { name: 'auth' },
  withProps<AuthProps>({ organisation: undefined, redirectPath: undefined })
);

persistState(authStore, {
  key: 'auth',
  storage: localStorageStrategy,
  source: () => authStore.pipe(excludeKeys(['organisation'])),
});

export class AuthRepository {
  organisation$ = authStore.pipe(select((state) => state?.organisation));

  canAccess$ = this.organisation$.pipe(map((org) => org?.canAccess || []));

  redirectPath$ = authStore.pipe(select((state) => state?.redirectPath));

  updateUser(organisation: AuthProps['organisation']) {
    authStore.update((state) => ({
      ...state,
      organisation,
    }));
  }

  updateRedirectPath(redirectPath: AuthProps['redirectPath']) {
    authStore.update((state) => ({
      ...state,
      redirectPath,
    }));
  }
}
