import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NumericUtilOrHumid } from 'src/app/models/solution';
import { DateTime } from 'luxon';

import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { environment } from 'src/environments/environment';
import { PolytechUiDatePickerComponent } from '../polytech-ui/date-picker/date-picker.component';
import { DateConversion } from '../polytech-ui/shared/helpers/static/date-conversion';
import { ResponseObj } from 'src/app/models/errorHandling';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ComponentState } from 'src/app/models/component';
import { isDefined } from '../utils';
import { UserService } from 'src/app/services/api/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

export type FilterQueryParams = number | string | string[] | ComponentState[];
type HttpParamTypes = string | number | readonly string[] | readonly number[];

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, PolytechUiDatePickerComponent, NgIf, TranslateModule],
})
export class ExportDialogComponent implements OnInit, AfterViewInit {
  exportGroup: UntypedFormGroup = new UntypedFormGroup({});
  typeControl = new UntypedFormControl('_all_');
  projectId: string;
  ids: string[];
  exporting: boolean;

  @ViewChild('datePickerRef') datePickerRef: PolytechUiDatePickerComponent;

  constructor(
    private http: HttpClient,
    private responseHandler: ResponseHandlerService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      solutionSpace: NumericUtilOrHumid;
      componentsInputs:
        | {
            ids: string[];
            timeStart: Date | undefined;
            timeEnd: Date | undefined;
          }
        | undefined;
      filterQueryParams: Record<string, FilterQueryParams>;
    },
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    private snackBar: SnackbarService
  ) {
    dialogRef.disableClose = true;
  }

  private _canExport = false;
  ngOnInit(): void {
    if (this.data.componentsInputs !== undefined) {
      this.ids = this.data.componentsInputs.ids;
    }
  }

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      this._canExport = true;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  createExport(event: { start: number; end: number }): void {
    const { start, end } = event;

    let exportLink;
    const orgId = this.userService.getOrganisationId() || '<unknown>';
    let params: Record<string, HttpParamTypes> = {};

    if (start) {
      params['dateTimeLimitFrom'] = DateConversion.toISO(start);
    }

    if (end) {
      const endOfDay = DateConversion.dayEndFromDate(new Date(end));
      params['dateTimeLimitTo'] = DateConversion.toISO(endOfDay);
    }

    let body;

    if (this.ids) {
      body = this.ids;
      exportLink = `${environment.api}/api/${orgId}/Component/export/ids`;
    } else {
      if (this.data.filterQueryParams) {
        const filterParams = Object.fromEntries(
          Object.entries(this.data.filterQueryParams)
            .filter(([_, value]) => isDefined(value))
            .map(([key, value]): [string, HttpParamTypes] => {
              return [key, value];
            })
        );

        params['solutionSpace'] = this.data.solutionSpace;

        params = { ...params, ...filterParams };
      }

      exportLink = `${environment.api}/api/${orgId}/Component/export/filter`;
    }

    if (this._canExport) {
      const obj: ResponseObj = {
        title: 'Loading',
        message: 'Creating export data',
      };
      this.snackBar.openSnackBar(obj, 'LOADING');

      this.exporting = true;
      this.http
        .post(exportLink, body, {
          params,
        })
        .subscribe({
          next: () => {
            const obj: ResponseObj = {
              title: 'Success',
              message: 'You will receive an email when the requested data is ready. Remember to check you spam folder.',
            };
            this.snackBar.openSnackBar(obj, 'ACKNOWLEGDE');
            this.exporting = false;
            this.close();
          },
          error: (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          },
        });
    }
  }

  selectAllData(): void {
    const startDate: DateTime = DateTime.fromISO('2020-01-01', { setZone: true, zone: 'Africa/Abidjan' });

    this.datePickerRef.setStartDate(startDate.toJSDate());
    this.datePickerRef.resetCalendars();
  }
}
