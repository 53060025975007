import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Incident, IncidentUpdateState } from 'src/app/models/incident';
import { IncidentType } from 'src/app/services/api/incidents.service';
import { incidentReadout } from 'src/app/models/transforms';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectFieldComponent } from '../../select-field/select-field.component';

@Component({
  selector: 'app-incident-dialog',
  templateUrl: './incident-dialog.component.html',
  styleUrls: ['./incident-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
  standalone: true,
  imports: [
    MatDialogModule,
    SelectFieldComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class IncidentDialogComponent implements OnInit {
  actionControl: UntypedFormControl = new UntypedFormControl();
  commentControl: UntypedFormControl = new UntypedFormControl();
  commentLength = 0;
  readonly maxCommentLength = 128;

  incidentView: Incident;
  dialogState: {
    action: IncidentUpdateState;
    shelfTime: string;
    comment: string;
  } = {
    action: IncidentUpdateState.Open,
    shelfTime: '',
    comment: '',
  };
  title: string;

  constructor(
    private dialogRef: MatDialogRef<IncidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Incident,
    private translate: TranslatePipe
  ) {
    dialogRef.disableClose = true;
    this.incidentView = data;
    this.actionControl.setValue(this.incidentView.isAcknowledged ? 'Resolved' : 'Resolve');
    this.dialogState.action = IncidentUpdateState.Acknowledge; //IncidentUpdateAction[this.incidentView.action.value as keyof typeof IncidentUpdateAction];
    this.commentControl.setValue(this.incidentView.comment);
    this.commentLength = this.incidentView.comment.length;
    this.dialogState.comment = this.incidentView.comment;
    this.dialogState.shelfTime = '7';
  }

  getTitle(): string {
    switch (this.incidentView.incidentType) {
      case IncidentType.NONE:
        return 'Ok';
      case IncidentType.NormalizedShockEnergy:
        return this.translate.transform('component.INCIDENTS.DIALOG.SHOCK_INCIDENT_TITLE', {
          readout: incidentReadout(this.incidentView.incidentType, this.incidentView.measurementValue),
        }) as string;
      case IncidentType.HUMIDITY:
        return this.translate.transform('component.INCIDENTS.DIALOG.HUMIDITY_INCIDENT_TITLE', {
          readout: incidentReadout(this.incidentView.incidentType, this.incidentView.measurementValue),
        }) as string;
      case IncidentType.BATTERY_LEVEL:
        return this.translate.transform('component.INCIDENTS.DIALOG.BATTERY_INCIDENT_TITLE') as string;
      default:
        return '<error>';
    }
  }

  ngOnInit(): void {
    this.title = this.getTitle();
  }

  /* get isShelf(): boolean {
    return false; //this.dialogState.action === IncidentUpdateAction.Shelf;// TODO CLEANUP!
  }
 */
  onActionChange(ctrl: UntypedFormControl): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.dialogState.action = IncidentUpdateState.Acknowledge; // IncidentUpdateAction[ctrl.value as keyof typeof IncidentUpdateAction];
  }

  onShelfChange(ctrl: UntypedFormControl): void {
    this.dialogState.shelfTime = ctrl.value as string;
  }

  onCommentChange(): void {
    const comment = this.commentControl.value as string;
    if (comment.length > this.maxCommentLength) {
      const clippedComment = comment.substring(0, this.maxCommentLength);
      this.commentControl.setValue(clippedComment);
      this.commentLength = clippedComment.length;
      this.dialogState.comment = clippedComment;
    } else {
      this.commentLength = comment.length;
      this.dialogState.comment = comment;
    }
  }
}
