<button
  [disabled]="disabled"
  (click)="rippleRef.createRipple($event); onClick($event)"
  [ngClass]="{ isInactive: (uiInactive$ | async), secondary: secondary }"
  type="{{ buttonType }}"
  tabindex="0"
  #buttonRef
>
  <ng-content></ng-content>
  <app-ui-click-ripple #rippleRef></app-ui-click-ripple>
</button>
