/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgFor, NgClass, AsyncPipe } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatCardModule } from '@angular/material/card';

interface IButton {
  name: string;
  iconSrc: string;
  count: number;
  selected: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelText: Observable<any>;
}

@Component({
  selector: 'app-map-overlay-card',
  templateUrl: './map-overlay-card.component.html',
  styleUrls: ['./map-overlay-card.component.scss'],
  providers: [TranslatePipe],
  standalone: true,
  imports: [MatCardModule, FlexModule, NgFor, NgClass, ExtendedModule, AngularSvgIconModule, AsyncPipe],
})
export class MapOverlayCardComponent implements OnInit, AfterViewInit {
  @Input() title?: string;
  @Input() availableCount?: number;
  @Input() inUseCount?: number;
  @Input() warningCount?: number;
  @Input() humidityCount?: number;
  @Input() incidentCount?: number;

  @Input() solution: SolutionSpace;
  @Output() filterChange = new EventEmitter<string>();

  buttons?: IButton[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  translateText(tranlateKey: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.translate.transform('MAP_OVERLAYCARD.' + tranlateKey);
  }

  constructor(private translate: TranslatePipe, private translateService: TranslateService) {}

  ngOnInit(): void {
    if (this.solution === SolutionSpace.Utilization) {
      this.buttons = [
        {
          name: 'available',
          iconSrc: '/assets/images/map/icon-40-not-in-use.svg',
          selected: false,
          count: this.availableCount!,
          labelText: this.translateService.get('MAP_OVERLAYCARD.AVAILABLE'),
        },
        {
          name: 'in-use',
          iconSrc: '/assets/icons/icon-40-in-use.svg',
          selected: false,
          count: this.inUseCount!,
          labelText: this.translateService.get('MAP_OVERLAYCARD.IN_USE'),
        },
        {
          name: 'warning',
          iconSrc: '/assets/icons/icon-40-incidents.svg',
          selected: false,
          count: this.warningCount!,
          labelText: this.translateService.get('MAP_OVERLAYCARD.WARNING'),
        },
      ];
    } else {
      this.buttons = [
        {
          name: 'humidity',
          iconSrc: '/assets/images/icon-40-humidity-yellow.svg',
          selected: false,
          count: this.humidityCount!,
          labelText: this.translateService.get('MAP_OVERLAYCARD.HUMIDITY'),
        },
        {
          name: 'incidents',
          iconSrc: '/assets/images/icon-40-alert.svg',
          selected: false,
          count: this.incidentCount!,
          labelText: this.translateService.get('MAP_OVERLAYCARD.INCIDENTS'),
        },
      ];
    }
  }

  ngAfterViewInit(): void {}

  clickHandler(button: IButton): void {
    let eventName = '';
    if (button.selected) {
      button.selected = false;
      eventName = 'none';
    } else {
      eventName = button.name;
      this.buttons!.forEach((btn) => {
        btn.selected = false;
      });
      button.selected = true;
    }
    this.filterChange.emit(eventName);
  }
}
