import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ZXComponent } from 'src/app/models/component';
import { SolutionSpace } from 'src/app/models/solution';
import { ComponentsService } from 'src/app/services/api/components.service';
import { ZxComponentFormComponent } from '../zx-component-form/zx-component-form.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-zx-component-edit',
  templateUrl: './zx-component-edit.component.html',
  styleUrls: ['./zx-component-edit.component.scss'],
  standalone: true,
  imports: [NgIf, ZxComponentFormComponent],
})
export class ZxComponentEditComponent implements OnInit, OnDestroy {
  component: ZXComponent;

  private paramSubscription: Subscription;

  constructor(private componentsService: ComponentsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.queryParams.subscribe((el) => {
      const solutionSpace = el.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;

      const componentId = el.id as string;
      this.componentsService.getComponent(solutionSpace, componentId).subscribe((component) => {
        this.component = component;
      });
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }
}
