import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DateInterval } from 'src/app/models/date.model';
import { FilterTypes } from 'src/app/models/filter';
import { LocationInfo, LocationType } from 'src/app/models/locations';
import { Area } from 'src/app/models/map/map.model';
import { FilterUpdate } from 'src/app/zx-components/zx-components-list/zx-components.component';
import { convertDateTime, isDefined } from '../../utils';
import { IntervalType } from '../number-range-input/number-range-input.component';
import {
  DaysInStateFilterState,
  FilterDaysInStateComponent,
} from './filter-days-in-state/filter-days-in-state.component';
import { SolutionSpace } from 'src/app/models/solution';
import { FormatDatePipe } from '../../pipes/format-date-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FilterTemperatureComponent } from './filter-temperature/filter-temperature.component';
import { FilterHumidityComponent } from './filter-humidity/filter-humidity.component';
import { FilterLastCommunicatedComponent } from './filter-last-communicated/filter-last-communicated.component';
import { FilterProjectComponent } from './filter-project/filter-project.component';
import { FilterLocationComponent } from './filter-location/filter-location.component';
import { FilterTypeComponent } from './filter-type/filter-type.component';
import { FilterUtilizationComponent } from './filter-utilization/filter-utilization.component';
import { FilterMeasurementComponent } from './filter-measurement/filter-measurement.component';
import { SearchFieldComponent } from '../../search-field/search-field.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf, NgClass } from '@angular/common';

export type Filter = FilterOption & FilterState;

export type FilterOption = {
  filterType: FilterType;
  label: string;
  icon: string;
  shouldShowInList: boolean;
  solution?: SolutionSpace;
};

export type FilterState = {
  disabled: boolean;
  isActive: boolean;
  tooltipText: string;
};

export enum FilterType {
  ARCHIVED,
  AREA,
  DAYSINSTATE,
  HUMIDITY,
  LASTCOMMUNICATED,
  LOCATION,
  MEASUREMENT,
  PROJECTS,
  TEMPERATURE,
  TYPES,
  UTILIZATION,
}

export type FilterIntervalOption = {
  intervalType: IntervalType | undefined;
  GTE: number | undefined;
  LTE: number | undefined;
};

export type SelectableOption = {
  value: FilterTypes | string;
  label: string;
  isSelected?: boolean;
};

export type SelectableRangeOption = SelectableOption & {
  currentCycleGTE?: number | undefined;
  currentCycleLTE?: number | undefined;
};

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    MatButtonModule,
    MatTooltipModule,
    AngularSvgIconModule,
    NgIf,
    NgClass,
    ExtendedModule,
    SearchFieldComponent,
    FilterMeasurementComponent,
    FilterUtilizationComponent,
    FilterTypeComponent,
    FilterLocationComponent,
    FilterDaysInStateComponent,
    FilterProjectComponent,
    FilterLastCommunicatedComponent,
    FilterHumidityComponent,
    FilterTemperatureComponent,
    TranslateModule,
    FormatDatePipe,
  ],
})
export class FilterComponent implements OnInit, OnDestroy {
  @ViewChild('filterMenuContainer') filterMenuContainer: ElementRef | undefined;

  @Input() set filterOptions(filterOptions: FilterOption[]) {
    this.filters = filterOptions.map((filterOption) => ({
      ...filterOption,
      disabled: false,
      isActive: false,
      tooltipText: '',
    }));
  }
  @Input() projects: SelectableOption[] = [];
  @Input() toggleSearchBar = true;
  @Input() types: { value: string; label: string; isSelected?: boolean }[] = [];
  @Output() updateData: EventEmitter<FilterUpdate> = new EventEmitter<FilterUpdate>();

  activeFilters: Filter[] = [];
  archived = false;
  filters: Filter[] = [];
  isDateUpdated = false;
  isFilterClicked = false;
  lastCommuncatedDateRange: DateInterval | undefined = undefined;
  measurements: SelectableOption[] = [];
  searchInAreaCoords: Area | undefined = undefined;
  searchText = '';
  selectedFilter: FilterType | undefined;
  selectedDaysInStateFilterState: DaysInStateFilterState = {
    selectedUtilizationState: '',
  };
  selectedHumidityIntervalOption: FilterIntervalOption | undefined;
  selectedTemperatureIntervalOption: FilterIntervalOption | undefined;
  selectedMeasurement: SelectableOption | undefined;
  selectedLocations: LocationInfo[] = [];
  selectedProjects: Array<SelectableOption> = [];
  selectedStatus: SelectableRangeOption[];
  selectedStatusLength = 0;
  selectedTypes: Array<SelectableOption> = [];
  utilizationOptions: SelectableOption[] = [
    { label: 'Available', value: FilterTypes.AVAILABLE },
    { label: 'In use', value: FilterTypes.INUSE },
    { label: 'Return', value: FilterTypes.IN_RETURN },
    { label: 'Service', value: FilterTypes.IN_SERVICE },
  ];

  FilterType = FilterType;

  private unlisten: (() => void) | undefined;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.selectedStatus = this.utilizationOptions.map((option) => ({ ...option }));

    const measurementFilter = this.filters.find((option) => option.filterType === FilterType.MEASUREMENT);
    if (measurementFilter) {
      if (measurementFilter.solution === SolutionSpace.Utilization) {
        this.measurements = [
          { label: 'FILTER.MEASUREMENT.ALL', value: FilterTypes.ALL },
          { label: 'FILTER.MEASUREMENT.AVAILABLE', value: FilterTypes.AVAILABLE },
          { label: 'FILTER.MEASUREMENT.IN_USE', value: FilterTypes.INUSE },
          { label: 'FILTER.MEASUREMENT.IN_RETURN', value: FilterTypes.IN_RETURN },
          { label: 'FILTER.MEASUREMENT.IN_SERVICE', value: FilterTypes.IN_SERVICE },
        ];
      } else {
        this.measurements = [
          { label: 'FILTER.MEASUREMENT.ALL_MEASUREMENTS', value: FilterTypes.ALL },
          { label: 'FILTER.MEASUREMENT.NSE', value: FilterTypes.SHOCK },
          { label: 'FILTER.MEASUREMENT.HUMIDITY', value: FilterTypes.HUMIDITY },
        ];
      }
    }

    this.setGlobalClickListener();
  }

  ngOnDestroy() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  applyAreaFilter(area: Area) {
    this.searchInAreaCoords = area;
    this.onApplyFilter(FilterType.AREA);
  }

  closePicker() {
    requestAnimationFrame(() => {
      this.selectedFilter = undefined;
    });
  }

  disableFilter(filter: Filter, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    filter.isActive = false;
    this.activeFilters = this.filters.filter((filter) => filter.isActive);
    switch (filter.filterType) {
      case FilterType.ARCHIVED:
        this.archived = false;
        break;
      case FilterType.LOCATION:
        this.selectedLocations = [];
        break;
      case FilterType.TYPES:
        {
          this.selectedTypes = [];
          this.types = this.types.map((type) => {
            type.isSelected = false;
            return type;
          });
        }
        break;
      case FilterType.PROJECTS:
        {
          this.selectedProjects = [];
          this.projects = this.projects.map((project) => {
            project.isSelected = false;
            return project;
          });
        }
        break;
      case FilterType.DAYSINSTATE:
        {
          this.selectedDaysInStateFilterState = {
            selectedUtilizationState: '',
          };

          this.selectedStatus = this.utilizationOptions.map((option) => ({ ...option }));
          this.filters.map((filter) => {
            if (filter.filterType === FilterType.UTILIZATION) {
              filter.disabled = false;
            }
          });
        }
        break;
      case FilterType.UTILIZATION:
        this.selectedStatus = this.utilizationOptions.map((option) => ({ ...option }));
        this.filters.map((filter) => {
          if (filter.filterType === FilterType.DAYSINSTATE) {
            filter.disabled = false;
          }
        });
        break;
      case FilterType.LASTCOMMUNICATED:
        this.lastCommuncatedDateRange = undefined;
        break;
      case FilterType.HUMIDITY:
        {
          this.selectedHumidityIntervalOption = undefined;
        }
        break;
      case FilterType.MEASUREMENT:
        this.measurements.forEach((option) => {
          option.isSelected = false;
        });
        this.selectedMeasurement = undefined;
        break;
      case FilterType.TEMPERATURE:
        {
          this.selectedTemperatureIntervalOption = undefined;
        }
        break;
      case FilterType.AREA:
        this.searchInAreaCoords = undefined;
        break;
    }

    this.update();
  }

  goToCurrentClickedFilter(filter: Filter, event: Event): void {
    if (filter.filterType === FilterType.AREA) {
      return;
    }

    this.selectedFilter = filter.filterType;
    this.isFilterClicked = !this.isFilterClicked;
    event.stopPropagation();
    event.preventDefault();
  }

  onApplyFilter(type: FilterType): void {
    this.selectedFilter = undefined;

    // set the type filters to visited
    const chosenFilter = this.filters.find((filter) => filter.filterType === type);
    if (!isDefined(chosenFilter)) {
      return;
    }
    chosenFilter.isActive = false;
    chosenFilter.tooltipText = this.getTooltipText(type);

    switch (type) {
      case FilterType.AREA:
        chosenFilter.isActive = true;
        break;
      case FilterType.DAYSINSTATE:
        // eslint-disable-next-line no-case-declarations
        const selectedState = this.utilizationOptions.find(
          (util) => util.value === this.selectedDaysInStateFilterState.selectedUtilizationState
        );

        if (isDefined(selectedState)) {
          chosenFilter.isActive = true;
          this.filters.map((filter) => {
            // disable the utilization filter when the days in state  filter is selected
            if (filter.filterType === FilterType.UTILIZATION) {
              filter.disabled = true;
            }
          });

          this.selectedStatus = this.selectedStatus.map((status) => ({
            ...status,
            currentCycleLTE:
              selectedState.label === status.label
                ? this.selectedDaysInStateFilterState.selectedIntervalOption?.LTE
                : undefined,
            currentCycleGTE:
              selectedState.label === status.label
                ? this.selectedDaysInStateFilterState.selectedIntervalOption?.GTE
                : undefined,
          }));
        } else {
          this.selectedDaysInStateFilterState = {
            selectedUtilizationState: '',
          };
        }
        break;
      case FilterType.LASTCOMMUNICATED:
        if (isDefined(this.lastCommuncatedDateRange)) {
          chosenFilter.isActive = true;
        }
        break;
      case FilterType.LOCATION:
        if (this.selectedLocations.length > 0) {
          chosenFilter.isActive = true;
        }
        break;
      case FilterType.MEASUREMENT:
        this.measurements.forEach((option) => {
          if (this.selectedMeasurement?.value === option.value) {
            option.isSelected = true;
            chosenFilter.isActive = true;
          } else {
            option.isSelected = false;
          }
        });
        break;
      case FilterType.PROJECTS:
        if (this.selectedProjects.length > 0) {
          chosenFilter.isActive = true;
        }
        break;
      case FilterType.TYPES:
        if (this.selectedTypes.length > 0) {
          chosenFilter.isActive = true;
        }
        break;
      case FilterType.UTILIZATION:
        if (this.selectedStatusLength > 0) {
          chosenFilter.isActive = true;
          this.filters.map((filter) => {
            if (filter.filterType === FilterType.DAYSINSTATE) {
              filter.disabled = true;
            }
          });
        } else {
          this.filters.map((filter) => {
            if (filter.filterType === FilterType.DAYSINSTATE) {
              filter.disabled = false;
            }
          });
        }
        break;
      case FilterType.HUMIDITY:
        if (this.selectedHumidityIntervalOption?.GTE || this.selectedHumidityIntervalOption?.LTE) {
          chosenFilter.isActive = true;
        }
        break;
      case FilterType.TEMPERATURE:
        if (this.selectedTemperatureIntervalOption?.GTE || this.selectedTemperatureIntervalOption?.LTE) {
          chosenFilter.isActive = true;
        }
        break;
    }

    this.activeFilters = this.filters.filter((filter) => filter.isActive);

    this.update();
  }

  onDaysInStateOptionChange(daysInStateFilterState: DaysInStateFilterState) {
    this.selectedDaysInStateFilterState = daysInStateFilterState;

    this.selectedStatus.forEach((option) => {
      if (option.value === daysInStateFilterState.selectedUtilizationState) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    });
  }

  onLocationOptionChange(selectedLocations: LocationInfo[]) {
    this.selectedLocations = selectedLocations;
  }

  onMeasurementOptionChange(selectedOption: SelectableOption) {
    this.selectedMeasurement = selectedOption;
  }

  onSelectProjects(selectedOptions: SelectableOption[]) {
    this.projects.forEach((option) => {
      if (selectedOptions.some((selectedOption) => selectedOption.label === option.label)) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    });
    this.selectedProjects = selectedOptions;
  }

  onTypeOptionChange(selectedOptions: SelectableOption[]) {
    this.types.forEach((option) => {
      if (selectedOptions.some((selectedOption) => selectedOption.label === option.label)) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    });
    this.selectedTypes = selectedOptions;
  }

  onUpdateHumidityRange(range: FilterIntervalOption) {
    this.selectedHumidityIntervalOption = range;
  }

  onUpdateTemperatureRange(range: FilterIntervalOption) {
    this.selectedTemperatureIntervalOption = range;
  }

  onUtilizationOptionChange(selectedOptions: SelectableOption[]) {
    this.selectedStatusLength = selectedOptions.length;
    this.selectedStatus.forEach((option) => {
      if (selectedOptions.some((selectedOption) => selectedOption.label === option.label)) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    });
  }

  removeLocation(index: number): void {
    this.selectedLocations.splice(index, 1);
  }

  resetMenuContext() {
    if (this.selectedFilter) {
      this.onApplyFilter(this.selectedFilter);
    }
    this.selectedFilter = undefined;
    this.isFilterClicked = false;
  }

  selectFilter(filter: Filter, event: Event) {
    this.selectedFilter = filter.filterType;
    event.stopPropagation();

    switch (filter.filterType) {
      case FilterType.ARCHIVED: {
        this.archived = true;
        filter.isActive = true;
        this.activeFilters = this.filters.filter((elem) => elem.isActive);
        this.update();
        break;
      }

      case FilterType.LASTCOMMUNICATED: {
        this.isDateUpdated = false;
        break;
      }
    }
  }

  setFilterSelectionStyle(event: Event): void {
    event.stopPropagation();
    this.isFilterClicked = !this.isFilterClicked;
    this.selectedFilter = undefined;
  }

  setGlobalClickListener() {
    this.unlisten = this.renderer.listen('document', 'click', (event: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (this.filterMenuContainer && !this.filterMenuContainer.nativeElement.contains(event.target)) {
        this.resetMenuContext();
      }
    });
  }

  setSearchControl(search: string): void {
    this.searchText = search;

    this.update();
  }

  update(): void {
    this.updateData.emit({
      search: this.searchText,
      archived: this.archived,
      types: this.selectedTypes.map((types) => types.value),
      projects: this.selectedProjects.map((project) => project.value),
      availables: this.selectedStatus.filter((status) => status.isSelected),
      filteredCountries: this.selectedLocations
        .filter((location) => location.type === LocationType.COUNTRY)
        .map((country) => country.id),
      filteredRegions: this.selectedLocations
        .filter((location) => location.type === LocationType.REGION)
        .map((region) => region.id),
      filteredPlaces: this.selectedLocations
        .filter((location) => location.type === LocationType.PLACE)
        .map((place) => place.id),
      filteredPostcodes: this.selectedLocations
        .filter((location) => location.type === LocationType.POSTCODE)
        .map((postcode) => postcode.id),
      lastCommunicationTimeFrom: this.lastCommuncatedDateRange?.start,
      lastCommunicationTimeTo: this.lastCommuncatedDateRange?.end,
      measurement: this.selectedMeasurement?.value,
      humidityValues: { GTE: this.selectedHumidityIntervalOption?.GTE, LTE: this.selectedHumidityIntervalOption?.LTE },
      temperatureValues: {
        GTE: this.selectedTemperatureIntervalOption?.GTE,
        LTE: this.selectedTemperatureIntervalOption?.LTE,
      },
      area: this.searchInAreaCoords,
    });
  }

  updateLastCommunicatedDateRange(evt: { start: number; end: number }) {
    if (this.isDateUpdated) {
      this.lastCommuncatedDateRange = { start: convertDateTime(evt.start), end: convertDateTime(evt.end, 1) };
      this.onApplyFilter(FilterType.LASTCOMMUNICATED);
    }
    this.isDateUpdated = true;
  }

  private getTooltipText(filterValue: FilterType): string {
    switch (filterValue) {
      case FilterType.LOCATION:
        return this.selectedLocations.map((location) => location.name).join(', ');
      case FilterType.TYPES:
        if (this.selectedTypes.length > 0) {
          return this.selectedTypes.map((type) => type.label).join(', ');
        } else return '';
      case FilterType.PROJECTS:
        if (this.selectedProjects.length > 0) return this.selectedProjects.map((project) => project.label).join(', ');
        else return '';
      case FilterType.UTILIZATION:
        if (this.selectedStatusLength > 0)
          return this.selectedStatus
            .filter((status) => status.isSelected)
            .map((status) => status.label)
            .join(', ');
        else return '';
      case FilterType.AREA:
        if (this.searchInAreaCoords) return 'Search in Area';
        break;
    }
    return '';
  }
}
