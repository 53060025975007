import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';
import { AdminModule } from './admin/admin.module';
import { AuthRepository } from './repositories/auth.repository';
import { CustomInterceptorService } from './services/authentication/custom-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { ZxComponentsModule } from './zx-components/zx-components.module';
import { ZxProjectsModule } from './zx-projects/zx-projects.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      OAuthModule.forRoot({
        resourceServer: {
          sendAccessToken: false,
        },
      }),
      AppRoutingModule,
      MatProgressSpinnerModule,
      MatCardModule,
      AngularSvgIconModule.forRoot(),
      BrowserModule,
      SharedModule,
      AdminModule,
      ZxProjectsModule,
      ZxComponentsModule,
      FormsModule,
      FlexLayoutModule,
      MatButtonModule,
      MatIconModule,
      MatSelectModule,
      MatInputModule,
      MatSlideToggleModule,
      MatDividerModule,
      MatTreeModule,
      MatTooltipModule,
      MatMenuModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      MatSidenavModule,
      MatDialogModule,
      ReactiveFormsModule,
      MatStepperModule,
      MatSnackBarModule,
      MatPaginatorModule
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptorService,
      multi: true,
    },
    AuthRepository,
    OAuthService,
    CookieService,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
};
