import { Component, OnInit } from '@angular/core';
import { ZxProjectFormComponent } from '../zx-project-form/zx-project-form.component';

@Component({
  selector: 'app-zx-project-create',
  templateUrl: './zx-project-create.component.html',
  styleUrls: ['./zx-project-create.component.scss'],
  standalone: true,
  imports: [ZxProjectFormComponent],
})
export class ZxProjectCreateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
