import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';
import { SolutionSpace } from '../../models/solution';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { ComponentsOverviewComponent } from '../../shared/components-overview/components-overview.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-zx-project-components-overview',
  templateUrl: './zx-project-components-overview.component.html',
  styleUrls: ['./zx-project-components-overview.component.scss'],
  standalone: true,
  imports: [NgIf, ComponentsOverviewComponent, AsyncPipe],
})
export class ZxProjectComponentsOverviewComponent {
  links = [
    {
      id: 'overview',
      label: 'component.componentOverviewTab',
      path: 'overview',
    },
    {
      id: 'insights',
      label: 'component.insightsTab',
      path: 'insights',
    },
    {
      id: 'datalog',
      label: 'component.componentDatalogTab',
      path: 'datalog',
    },
    {
      id: 'incidents',
      label: 'component.componentIncidentsTab',
      path: 'incidents',
    },
  ];

  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  projectId$ = this.route.params.pipe(map((el) => el.id as string));

  componentId$ = this.route.params.pipe(map((el) => el.componentId as string));

  component$ = combineLatest([this.solutionSpace$, this.projectId$, this.componentId$]).pipe(
    switchMap(([solutionSpace, projectId, componentId]) =>
      this.projectService.getComponent(solutionSpace, projectId, componentId)
    )
  );

  constructor(private projectService: ProjectsService, private route: ActivatedRoute) {}
}
