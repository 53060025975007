import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subscription, combineLatestWith, map } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { BackendComponent } from 'src/app/services/api/components.service';
import { IPin, MapViewComponent } from '../map-view/map-view.component';
import { UtilizationMapIcons } from 'src/app/models/map/utilizationType';
import { HumidityMapIcons } from 'src/app/models/map/humidityType';
import { ClusterFormatter } from '../cluster-formatter';
import { MapUtil } from '../map-util';
import { IPopupData, ISimplePopupData } from '../map-view/factories/popups/Interfaces';
import { stateToUtilizationIconMap, stateToUtilizationTextMap } from 'src/app/models/component';
import { PopupType } from '../map-view/factories/popups/FactoryPopup';
import { Area } from 'src/app/models/map/map.model';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, MapViewComponent, AsyncPipe],
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('mapView', { static: false }) mapView: MapViewComponent;

  @Input() set components(value: BackendComponent[]) {
    this._components.next(value);
  }
  @Input() solution: SolutionSpace;
  @Input() popupType = PopupType.Detailed;
  @Input() showInClusters = true;
  @Output() searchInArea: EventEmitter<Area> = new EventEmitter<Area>();

  _components = new BehaviorSubject<BackendComponent[]>([]);

  private positionData = this._components.pipe(map((components) => this.mapPositionData(components)));
  private clusterFormat: ClusterFormatter = new ClusterFormatter();
  private dataSub: Subscription;
  private lastPositionData: Array<IPin> = [];

  clusterPoints: BehaviorSubject<GeoJSON.FeatureCollection>;

  markerData = this.positionData.pipe(
    combineLatestWith(this._components),
    map(([positionData, components]) => [
      {
        image: 'assets/images/map/map-pin-clustered-tracking.png',
        position: positionData,
        clustered: this.showInClusters,
        popupFunction: (renderer: Renderer2, id: number) =>
          MapUtil.getPopup(renderer, this.popupType, this.solution, this.createPopupData(components, id)),
      },
    ])
  );

  ngOnDestroy(): void {
    this.dataSub && this.dataSub.unsubscribe();
  }

  ngOnInit(): void {
    if (this.showInClusters) {
      this.clusterPoints = this.clusterFormat.clusters;

      this.dataSub = this.positionData.subscribe((positionData) => {
        const shouldRenderCluster = !MapUtil.areEqual(this.lastPositionData, positionData);
        if (shouldRenderCluster) {
          this.lastPositionData = positionData;
          this.clusterFormat.format(this.lastPositionData);
        }
      });
    }
  }

  mapPositionData(components: BackendComponent[]): IPin[] {
    return components
      .filter((component) => component.position)
      .map((component) => {
        let status: string;
        let statusInt: number;

        if (this.solution === SolutionSpace.Utilization) {
          status = UtilizationMapIcons[component.state];
          statusInt = <number>component.state;
        } else {
          status = HumidityMapIcons[+component.componentHealth.activeHumidityIncident];
          statusInt = +component.componentHealth.activeHumidityIncident;
        }

        return {
          lat: component.position.lat,
          lng: component.position.lng,
          status: status,
          statusInt: statusInt,
        };
      });
  }

  onSearchInArea() {
    this.searchInArea.emit(this.mapView.getAreaCoords());
  }

  private createPopupData(components: BackendComponent[], id: number): IPopupData | ISimplePopupData {
    const component = components[id];

    if (this.popupType === PopupType.Simple) {
      return {
        state: component.state,
        id: component.id,
        position: component.position,
        utilization: {
          value: stateToUtilizationTextMap[component.state],
          iconLeading: stateToUtilizationIconMap[component.state],
        },
        humidity:
          this.solution === SolutionSpace.Humidity
            ? {
                value: component.componentHealth.latestHumidityMeasurement
                  ? `${Math.round(component.componentHealth.latestHumidityMeasurement)}%`
                  : 'N/A',
                value2: component.componentHealth.latestHumidityThreshold
                  ? ` / ${Math.round(component.componentHealth.latestHumidityThreshold)}%`
                  : ' / Not set',
              }
            : undefined,
      };
    }

    return {
      name: component.name,
      state: component.state,
      type: component.componentTypeDescription
        ? `${component.componentTypeName} - ${component.componentTypeDescription}`
        : component.componentTypeName,
      id: component.id,
      componentHealth: component.componentHealth,
      projectName: component.projectName,
      projectId: component.projectId,
    };
  }
}
