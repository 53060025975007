<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-attention.svg"></svg-icon>
  {{ dialogTitle() }}
</h2>

<h3>
  {{ data.numComponents }} components will be moved to the archive, and detached from connected devices. You will no
  longer receive data for these components.
</h3>
<p>Only Group admins can restore the components from the archive.</p>
<mat-dialog-actions>
  <button id="btn-archive-cancel" mat-flat-button [disabled]="archiveClicked" [mat-dialog-close]="'cancel'">
    Cancel
  </button>
  <button
    id="btn-archive-confirm"
    mat-flat-button
    [disabled]="archiveClicked"
    [class.spinner]="archiveClicked"
    (click)="onClick()"
    mat-primary
    color="primary"
  >
    Move to archive
  </button>
</mat-dialog-actions>
