import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  standalone: true,
})
export class OrganizationsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
