import { Component, OnInit } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AngularSvgIconModule, MatButtonModule, NgClass, ExtendedModule],
})
export class SaveDialogComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
