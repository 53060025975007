import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Type } from 'class-transformer';
import { Chart, ChartDataset, ChartOptions, Point } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { formatDate } from '../utils';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import 'chartjs-adapter-date-fns';
export class MetData {
  @Type(() => Date)
  date: Date;
  humidity: number;
  temperature: number;
  dewPoint: number;
  threshold: number;
  schock?: number;
  shockThreshold?: number;
}
Chart.register(zoomPlugin);
@Component({
  selector: 'app-acceleration-chart',
  templateUrl: './acceleration-chart.component.html',
  styleUrls: ['./acceleration-chart.component.scss'],
  standalone: true,
  imports: [NgChartsModule, NgIf, MatButtonModule, MatIconModule],
})
export class accelerationChartComponent implements OnInit, OnDestroy {
  private _data: MetData[] = [];
  @ViewChild(BaseChartDirective, { static: false }) scattedChart: BaseChartDirective;

  accelerationChartLabels: string[] = [];
  accelerationChartData: ChartDataset<'scatter' | 'line'>[] = [];
  pointBackgroundColor: Array<string> = [];
  pointBorderBackgroundColor: Array<string> = [];

  options: ChartOptions<'scatter' | 'line'> = this.setOptions();
  isZoomHidden = true;

  @Input()
  isMobile: boolean;
  @Input() set data(value: MetData[]) {
    if (value) {
      this._data = value.reverse();
      this.pointBackgroundColor = [];
      this.pointBorderBackgroundColor = [];
      this.setLabels();
      this.setDataSet();
      this.setPointBackgroundColor();
    }
  }

  constructor() {}

  setPointBackgroundColor() {
    this._data.forEach((metaData: MetData) => {
      if (metaData.schock! >= metaData.shockThreshold!) {
        this.pointBackgroundColor.push('#DC2D0A');
        this.pointBorderBackgroundColor.push('rgb(220,45,10,0.5)');
      } else {
        this.pointBackgroundColor.push('#4682B4');
        this.pointBorderBackgroundColor.push('rgba(70, 130, 180, 0.5)');
      }
    });
    if (this.scattedChart) this.scattedChart.update();
  }

  setLabels(): void {
    this.accelerationChartLabels = this._data.map((d) => d.date.toISOString());
  }
  setDataSet(): void {
    const accelerationThresholdData: Point[] =
      this._data.length > 1
        ? this._data.map((d) => ({ x: d.date.getTime(), y: d.shockThreshold ?? 0 }))
        : this._data.length === 1
        ? [
            { x: this._data[0].date.getTime(), y: this._data[0].shockThreshold ?? 0 },
            { x: new Date().getTime(), y: this._data[0].shockThreshold ?? 0 },
          ]
        : [
            { x: new Date().getTime(), y: 0 },
            { x: new Date().getTime(), y: 0 },
          ];

    this.accelerationChartData = [
      {
        type: 'scatter',
        data: this._data.map((data) => ({
          x: data.date.getTime(),
          y: data.schock ?? 0,
        })),
        label: 'Acceleration',
        pointBackgroundColor: this.pointBackgroundColor,
        pointBorderColor: this.pointBorderBackgroundColor,
        fill: false,
        pointRadius: 6,
        pointHoverRadius: 8,
        hoverBorderColor: 'rgba(70, 130, 180, 0.5)',
        backgroundColor: this.pointBackgroundColor,
        pointHoverBackgroundColor: this.pointBackgroundColor,
        pointBorderWidth: 10,
        borderColor: 'rgba(70, 130, 180, 1)',
        pointHoverBorderColor: this.pointBackgroundColor,
        pointHoverBorderWidth: 2,
      },
      {
        type: 'line',
        data: accelerationThresholdData,
        label: 'accelerationThreshold',
        showLine: true,
        fill: false,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',
        borderDash: [1, 1],
        borderDashOffset: 0.0,
        // pointBorderColor needs to be the same as the borders of the above threshold points
        pointBorderColor: 'rgb(220,45,10,0.5',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
      },
    ];
  }

  ngOnInit(): void {
    this.setOptions();
  }

  resetZoom(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.scattedChart.chart['resetZoom']();
    this.isZoomHidden = true;
  }

  setOptions(): ChartOptions<'scatter' | 'line'> {
    return {
      plugins: {
        zoom: {
          zoom: {
            mode: 'xy',
            onZoom: () => {
              this.isZoomHidden = false;
            },

            drag: {
              enabled: true,
              backgroundColor: 'rgba(173, 216, 230, 0.2)',
              borderColor: 'rgba(173, 216, 230, 1)',
              borderWidth: 1,
            },
          },
        },
        legend: {
          display: false,
        },
        tooltip: {
          position: 'nearest',
          displayColors: false,
          callbacks: {
            label: (context) => {
              const index = context.datasetIndex;
              if (index !== undefined && context.chart.data.labels) {
                const datePortion = formatDate(context.chart.data.labels[context.dataIndex] as Date);
                const point = context.raw as Point;
                return [`${datePortion}`, `NSE: ${point.y}`];
              }
              return '';
            },
          },
        },
      },
      responsive: true,
      layout: {
        padding: { top: 12, right: 12, bottom: 12 },
      },

      maintainAspectRatio: false,

      scales: {
        x: {
          type: 'time',
          bounds: 'ticks',
          ticks: {
            font: {
              size: 12,
            },
            color: '#000000',
            maxTicksLimit: 7,
            minRotation: 0,
            maxRotation: 0,
            source: 'auto',
          },
          time: {
            tooltipFormat: 'MMM dd HH:mm:ss',
            displayFormats: {
              millisecond: 'MMM dd HH:mm:ss',
              second: 'MMM dd HH:mm:ss',
              minute: 'MMM dd HH:mm',
              hour: 'MMM dd',
              day: 'MMM dd',
              week: 'MMM dd',
              month: 'MMM',
              quarter: 'MMM',
            },
          },
        },
        y: {
          title: {
            display: true,
            text: 'NSE index',
            color: 'black',
            font: { size: 15 },
          },
          beginAtZero: true,
          position: 'left',
          display: true,
          grid: { display: false },
          ticks: {
            stepSize: 2,
            padding: 20,
            color: 'black',
          },
        },
      },
    };
  }

  ngOnDestroy(): void {
    if (this.scattedChart) this.scattedChart.ngOnDestroy();
  }
}
