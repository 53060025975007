/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { FilterIntervalOption, FilterType } from '../filter/filter.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { InputFieldComponent } from '../../input-field/input-field.component';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

export type IntervalType = 'LTE' | 'GTE' | 'INTERVAL';

@Component({
  selector: 'app-number-range-input',
  templateUrl: './number-range-input.component.html',
  styleUrls: ['./number-range-input.component.scss'],
  standalone: true,
  imports: [MatRadioModule, FormsModule, NgFor, NgIf, InputFieldComponent, TranslateModule],
})
export class NumberRangeInputComponent implements OnInit {
  @Input() unit: string;
  @Input() selectedIntervalOption?: FilterIntervalOption;
  @Output() UpdateNumberRange: EventEmitter<FilterIntervalOption> = new EventEmitter<FilterIntervalOption>();

  FilterType = FilterType;

  intervaleOptions: Array<{ label: string; value: 'LTE' | 'GTE' | 'INTERVAL' }> = [];
  currentSelectedInterval: IntervalType | undefined;
  GTEValue: number | undefined = undefined;
  LTEValue: number | undefined = undefined;

  constructor() {
    this.intervaleOptions = [
      { label: 'FILTER.NUMBER_RANGE_INPUT.IS_LESS_THAN', value: 'LTE' },
      { label: 'FILTER.NUMBER_RANGE_INPUT.IS_GREATER_THAN', value: 'GTE' },
      { label: 'FILTER.NUMBER_RANGE_INPUT.IS_BETWEEN', value: 'INTERVAL' },
    ];
  }

  ngOnInit(): void {
    if (this.selectedIntervalOption) {
      this.currentSelectedInterval = this.selectedIntervalOption.intervalType;
      this.GTEValue = this.selectedIntervalOption.GTE;
      this.LTEValue = this.selectedIntervalOption.LTE;
    }
  }

  onTypeUpdated(value: IntervalType) {
    this.currentSelectedInterval = value;
    this.GTEValue = undefined;
    this.LTEValue = undefined;
    this.emitUpdate();
  }

  onLteUpdated(value: string) {
    this.LTEValue = value === '' ? undefined : +value;
    this.emitUpdate();
  }

  onGteUpdated(value: string) {
    this.GTEValue = value === '' ? undefined : +value;
    this.emitUpdate();
  }

  emitUpdate() {
    this.UpdateNumberRange.emit({
      intervalType: this.currentSelectedInterval,
      GTE: this.GTEValue,
      LTE: this.LTEValue,
    });
  }
}
