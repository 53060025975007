import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Measurement, MeasurementsType } from 'src/app/models/measurement';
import { SolutionSpace } from 'src/app/models/solution';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { MetData } from 'src/app/shared/meteorology-chart/meteorology-chart.component';
import { convertDateTime } from 'src/app/shared/utils';
import { DateTime } from 'luxon';
import { PolytechUiDatePickerComponent } from '../../shared/polytech-ui/date-picker/date-picker.component';
import { map, Observable } from 'rxjs';
import { DateInterval } from 'src/app/models/date.model';
import { accelerationChartComponent } from '../acceleration-chart/acceleration-chart.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MeteorologyChartComponent } from '../meteorology-chart/meteorology-chart.component';
import { PolytechUiDatePickerComponent as PolytechUiDatePickerComponent_1 } from '../polytech-ui/date-picker/date-picker.component';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor, NgStyle } from '@angular/common';

type GroupedMeasurements = { [key: string]: Omit<Measurement, 'timestamp'>[] };
@Component({
  selector: 'app-component-insights',
  templateUrl: './component-insights.html',
  styleUrls: ['./component-insights.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    PolytechUiDatePickerComponent_1,
    MeteorologyChartComponent,
    NgFor,
    NgStyle,
    ExtendedModule,
    accelerationChartComponent,
  ],
})
export class ComponentInsightsComponent implements OnInit {
  @Input() solutionSpace: SolutionSpace;
  @Input() measurememntsNSE$: Observable<Measurement[]>;
  @Input() measurememntsHumidity$: Observable<Measurement[]>;

  @Output() updateDateRangeHumidity: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();
  @Output() updateDateRangeNSE: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();

  humidityMetaData: MetData[] = [];
  accelerationMetData: MetData[] = [];
  humLabels: Array<{
    title: string;
    value: string;
    color: string;
    label: Array<{ key: string; value: string | null }>;
  }> = [];
  accelerationLegends: {
    threshold: string | number | undefined;
    highestNSE: string | number;
    aboveThres: string | number;
  } = {
    threshold: 'N/A',
    highestNSE: 'N/A',
    aboveThres: 'N/A',
  };

  @ViewChild('datePickerChockRef') datePickerChockRef: PolytechUiDatePickerComponent;
  @ViewChild('datePickerUtilRef') datePickerUtilRef: PolytechUiDatePickerComponent;

  constructor(private reponseHandler: ResponseHandlerService) {}

  ngOnInit(): void {
    this.measurememntsHumidity$
      .pipe(
        map((measurements) => this.groupMeasurements(measurements)),
        map((groupedMeasurements) => this.calculateHumidityMetDataFromMeasurements(groupedMeasurements))
      )
      .subscribe({
        next: (metData) => {
          this.humidityMetaData = metData;
          this.setupHumidityLabels(metData);
        },
        error: (error: HttpErrorResponse) => this.reponseHandler.error(error),
      });

    this.measurememntsNSE$
      .pipe(
        map((measurements) => this.groupMeasurements(measurements)),
        map((groupedMeasurements) => this.calculateNSEMetDataFromMeasurements(groupedMeasurements))
      )
      .subscribe({
        next: (metData) => {
          this.accelerationMetData = metData;
          this.setupAccelerationLabels(metData);
        },
        error: (error: HttpErrorResponse) => this.reponseHandler.error(error),
      });
  }

  groupMeasurements(measurements: Measurement[]): GroupedMeasurements {
    const grouppedMeasuements: GroupedMeasurements = {};
    measurements.forEach((measurement: Measurement) => {
      const index = new Date(measurement.timestamp).toISOString();
      grouppedMeasuements[index] = grouppedMeasuements[index] || [];
      grouppedMeasuements[index].push({
        measurementType: measurement.measurementType,
        value: measurement.value,
        preferencesAtTimeOfMeasurement: measurement.preferencesAtTimeOfMeasurement,
      });
    });
    return grouppedMeasuements;
  }

  calculateHumidityMetDataFromMeasurements(groupedMeasurements: GroupedMeasurements): MetData[] {
    const humidityMetaData: MetData[] = [];
    for (const group in groupedMeasurements) {
      const metData: MetData = {
        date: new Date(group),
        humidity: 0,
        temperature: 0,
        dewPoint: 0,
        threshold: 0,
        schock: 0,
        shockThreshold: 0,
      };
      if (groupedMeasurements[group][0].measurementType === MeasurementsType.Humidity) {
        metData.humidity = Math.round(groupedMeasurements[group][0].value);
        metData.temperature = Math.round(groupedMeasurements[group][1].value);
      } else if (groupedMeasurements[group][0].measurementType === MeasurementsType.Temperature) {
        metData.temperature = Math.round(groupedMeasurements[group][0].value);
        metData.humidity = Math.round(groupedMeasurements[group][1].value);
      }
      metData.dewPoint = Math.round(metData.temperature - (100 - metData.humidity) / 5);
      metData.threshold = Math.round(groupedMeasurements[group][0].preferencesAtTimeOfMeasurement.humidityThreshold);
      humidityMetaData.push(metData);
    }
    return humidityMetaData;
  }

  calculateNSEMetDataFromMeasurements(groupedMeasurements: GroupedMeasurements): MetData[] {
    const NSEMetaData: MetData[] = [];
    for (const group in groupedMeasurements) {
      const metData: MetData = {
        date: new Date(group),
        humidity: 0,
        temperature: 0,
        dewPoint: 0,
        threshold: 0,
        schock: 0,
        shockThreshold: 0,
      };
      if (groupedMeasurements[group][0].measurementType === MeasurementsType.NormalizedShockEnergy) {
        metData.schock =
          groupedMeasurements[group][0].value > 0
            ? Math.round(groupedMeasurements[group][0].value)
            : Math.round(-groupedMeasurements[group][0].value);
        metData.shockThreshold = Math.round(
          groupedMeasurements[group][0].preferencesAtTimeOfMeasurement.normalizedShockEnergyIncidentThreshold
        );
        NSEMetaData.push(metData);
      }
    }
    return NSEMetaData;
  }

  setupAccelerationLabels(metData: MetData[]): void {
    this.accelerationLegends = {
      threshold: 'N/A',
      highestNSE: 'N/A',
      aboveThres: 'N/A',
    };
    if (metData && metData.length > 0) {
      const accelerationMetDataLength = metData.length;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.accelerationLegends.threshold = metData[accelerationMetDataLength - 1].shockThreshold;
      this.accelerationLegends.highestNSE = Math.max(...metData.map((item) => item.schock!));
      this.accelerationLegends.aboveThres = metData.filter((item) => item.schock! > item.shockThreshold!).length;
    }
  }

  setupHumidityLabels(metData: MetData[]) {
    this.humLabels = [];
    const metadataLength = metData.length;
    const maxHumValue = metadataLength > 0 ? Math.max(...metData.map((item) => item.humidity)) : 0;
    const minHumValue = metadataLength > 0 ? Math.min(...metData.map((item) => item.humidity)) : 0;
    const aboveThresholdHum = metadataLength > 0 ? metData.filter((h1) => h1.humidity > h1.threshold).length : 0;
    const humSumValues = metadataLength > 0 ? metData.reduce((sum, h1) => (sum = sum + h1.humidity), 0) : 0;
    // the latest humMeasurement is the first pushed measurment in the array in the graph as chart js needs it in reverse !!!!
    const latestHumMeasurement = metadataLength > 0 ? metData[0].humidity : '';
    const latestTempMeasurement = metadataLength > 0 ? metData[0].temperature : '';
    const latestDewPointMeasurement = metadataLength > 0 ? metData[0].dewPoint : '';
    const humidityLabels: {
      title: string;
      value: string;
      color: string;
      label: Array<{ key: string; value: string | null }>;
    } = {
      title: `Humidity`,
      value: `${latestHumMeasurement} %`,

      color: '#4682B4',
      label: [
        // todo check threshold is variable
        {
          key: 'Threshold',
          value: metadataLength > 0 ? metData[0].threshold.toString() + '%' : 'N/A',
        },
        {
          key: 'Times above Threshold',
          value: aboveThresholdHum.toString(),
        },
        { key: 'Highest humidity', value: metadataLength > 0 ? maxHumValue.toString() + '%' : 'N/A' },
        { key: 'Lowest humidity', value: metadataLength > 0 ? minHumValue.toString() + '%' : 'N/A' },

        {
          key: 'Average humidity',
          value: metadataLength > 0 ? Math.round(humSumValues / metadataLength).toString() + '%' : 'N/A',
        },
        {
          key: 'Humidity range',
          value: metadataLength > 0 ? (maxHumValue - minHumValue).toString() + '%' : 'N/A',
        },
      ],
    };
    const dewPointLabels: {
      title: string;
      value: string;
      color: string;
      label: Array<{ key: string; value: string | null }>;
    } = {
      title: 'Dew Point',
      value: `${latestDewPointMeasurement} °C`,
      color: '#028767',

      label: [],
    };
    const TempMetadataLength = metData.length;
    const maxTempValue = metData.length > 0 ? Math.max(...metData.map((item) => item.temperature)) : 0;
    const minTempValue = metData.length > 0 ? Math.min(...metData.map((item) => item.temperature)) : 0;
    const TempSumValues = metData.length > 0 ? metData.reduce((sum, h1) => (sum = sum + h1.temperature), 0) : 0;
    const temoperatureLabels: {
      title: string;
      value: string;
      color: string;
      label: Array<{ key: string; value: string | null }>;
    } = {
      title: `Temperature`,
      value: `${latestTempMeasurement} °C`,
      color: '#dc2d0a',

      label: [
        { key: 'Highest measurement', value: TempMetadataLength > 0 ? maxTempValue.toString() + '°C' : 'N/A' },
        { key: 'Lowest measurement', value: TempMetadataLength > 0 ? minTempValue.toString() + '°C' : 'N/A' },
        {
          key: 'Average humidity',
          value: TempMetadataLength > 0 ? Math.round(TempSumValues / TempMetadataLength).toString() + '°C' : 'N/A',
        },
        {
          key: 'Humidity range',
          value: TempMetadataLength > 0 ? (maxTempValue - minTempValue).toString() + '°C' : 'N/A',
        },
      ],
    };

    this.humLabels.push(humidityLabels);
    this.humLabels.push(temoperatureLabels);
    this.humLabels.push(dewPointLabels);
  }

  updateDateRange(evt: { start: number; end: number }, type: 'ACCELERATION' | 'HUMIDITY'): void {
    switch (type) {
      case 'ACCELERATION':
        this.updateDateRangeNSE.emit({
          start: convertDateTime(evt.start),
          end: convertDateTime(evt.end, 1),
        });
        break;
      case 'HUMIDITY':
        this.updateDateRangeHumidity.emit({
          start: convertDateTime(evt.start),
          end: convertDateTime(evt.end, 1),
        });
        break;
    }
  }

  selectAllData(): void {
    const startDate: DateTime = DateTime.fromISO('2020-01-01', { setZone: true, zone: 'Africa/Abidjan' });

    try {
      this.datePickerUtilRef.setStartDate(startDate.toJSDate());
      this.datePickerUtilRef.resetCalendars();
    } catch (e: any) {
      /* stub */
    }
    try {
      this.datePickerChockRef.setStartDate(startDate.toJSDate());
      this.datePickerChockRef.resetCalendars();
    } catch (e: any) {
      /* stub */
    }
  }
}
