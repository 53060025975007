<a class="nav-cancel" (click)="clickNav()" id="btn-close-component-window">
  <svg-icon class="nav-icon" src="/assets/images/icon-40-close.svg"></svg-icon>
  <span>Close</span>
</a>
<div class="component-form">
  <form #componentForm="ngForm">
    <h2 class="step-header">{{ this.component ? "Edit Component" : "Create Component" }}</h2>

    <div class="form-field-container" *ngIf="doubleSolutionAccess">
      <p class="form-field-header">Logisitics Hub type</p>

      <app-select-field
        id="select-logistics-type"
        class="select-field inverted"
        (selectionChange)="form.updateSolution($event)"
        [optionArray]="[
          { value: 'utilization', label: 'Utilization' },
          { value: 'humidity', label: 'Humidity' }
        ]"
        [defaultChoice]="form.solution"
      ></app-select-field>
    </div>

    <div>
      <div class="form-field-container">
        <p class="form-field-header">Find logistics hub</p>

        <app-search-field
          id="input-logistics-id-search"
          class="search-field inverted"
          (keyUp)="updateDeviceSearch($event)"
          (focused)="updateDeviceSearch()"
          [placeHolder]="'Enter ID found on logistics hub label'"
          #searchcontrol
        ></app-search-field>
      </div>

      <div *ngIf="devices.length > 0" style="width: 100%">
        <h3 style="font-weight: 500; color: #aaaaaa">Search results</h3>

        <table style="width: 100%">
          <thead>
            <tr>
              <th>Device</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let device of devices">
              <tr>
                <td>{{ mapBackendDevice(device.name) }}</td>

                <td *ngIf="form.deviceAdded(device)" class="addedcell">
                  <button
                    [attr.data-id]="device.dbId"
                    [id]="'btn-added-' + device.name"
                    mat-flat-button
                    color="info"
                    ngProjectAs="buttons"
                  >
                    ✓ Added
                  </button>
                </td>

                <td *ngIf="!form.deviceAdded(device)" class="addcell">
                  <button
                    [disabled]="form.devices.length > 0"
                    (click)="addDevice($event)"
                    [attr.data-id]="device.dbId"
                    [id]="'btn-add-' + device.name"
                    mat-flat-button
                    color="primary"
                    ngProjectAs="buttons"
                  >
                    + Add
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

      <div *ngIf="form.devices.length > 0" style="width: 100%">
        <hr style="width: 50px; float: left; margin: 20px 0px" />

        <h4 style="font-size: 20px; font-weight: 700; margin-bottom: 30px; clear: both">Added device</h4>

        <table style="width: 100%">
          <thead>
            <tr>
              <th>Device</th>
            </tr>
          </thead>

          <tbody>
            <ng-container *ngFor="let device of addedDevices()">
              <tr>
                <td>{{ mapBackendDevice(device.name) }}</td>

                <td class="addedcell">
                  <button
                    (click)="removeDevice($event)"
                    [attr.data-id]="device.dbId"
                    [id]="'btn-remove-' + device.name"
                    mat-flat-button
                    color="info"
                    ngProjectAs="buttons"
                  >
                    X Remove
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <div class="form-field-container">
      <hr class="add-device-wrapper" />

      <h4 class="add-device-header">Components</h4>

      <p class="form-field-header">
        Component ID
        <span *ngIf="!form.name || form.name.length < 1" class="required">*</span>
      </p>

      <app-input-field
        id="input-component-id"
        class="input-field inverted"
        (valueChanged)="form.updateName($event)"
        [defaultValue]="form.name"
      ></app-input-field>
    </div>

    <div class="form-field-container">
      <p class="form-field-header">
        Component Type
        <span *ngIf="typePristine()" class="required">*</span>
      </p>

      <app-type-field
        id="select-component-type"
        class="select-field inverted"
        (selectionChange)="form.updateType($event)"
        (createNew)="createComponentType()"
        (edit)="editComponentType($event)"
        [solution]="solutionValue"
        [optionArray]="types"
        [defaultChoice]="initialType"
      ></app-type-field>
    </div>

    <div class="form-field-container">
      <p class="form-field-header">
        Group
        <span *ngIf="groupPristine()" class="required">*</span>
      </p>

      <app-select-field
        id="select-group"
        class="select-field inverted"
        (selectionChange)="form.updateGroup($event)"
        [disabled]="!(groups && groups.length > 1)"
        [optionArray]="groupValues"
        [defaultChoice]="defaultGroup"
      ></app-select-field>
    </div>
  </form>

  <div class="archive-update-container">
    <div class="archive-container">
      <button
        class="btn-archive btn-red"
        mat-flat-button
        id="btn-archive"
        color="warning"
        (click)="openArchiveDialog()"
        *ngIf="component !== undefined && component.state !== ComponentStateEnum.Archived"
      >
        Archive component
      </button>
    </div>
  </div>

  <hr />

  <!-- end add device -->

  <div>
    <div style="float: left">
      <button (click)="onCancel()" id="btn-cancel" [ngClass]="'btn-grey'" mat-flat-button>Cancel</button>
    </div>

    <div style="float: right">
      <button
        mat-flat-button
        *ngIf="component === undefined"
        (click)="onSubmit()"
        color="primary"
        id="btn-create-component"
        [ngClass]="'btn-submit'"
        [disabled]="!formValid()"
        [class.spinner]="creatingComponent"
      >
        Create component
      </button>

      <button
        mat-flat-button
        *ngIf="component !== undefined"
        (click)="onUpdate()"
        color="primary"
        id="btn-update-editComponent"
        [ngClass]="'btn-submit'"
        [disabled]="saveButtonDisabled()"
        [class.spinner]="updatingComponent"
      >
        Update
      </button>
    </div>
  </div>
</div>
