<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-attention.svg"></svg-icon>
  {{ dialogTitle() }}
</h2>

<h3>The component will be moved to the archive, and all devices will be detached the component.</h3>
<p>All data from the project will still be available.</p>
<mat-dialog-actions>
  <button id="btn-archive-cancel" mat-flat-button [disabled]="archiveClicked" [mat-dialog-close]="'cancel'">
    Cancel
  </button>
  <button
    id="btn-archive-confirm"
    mat-flat-button
    [disabled]="archiveClicked"
    [class.spinner]="archiveClicked"
    (click)="onClick()"
    mat-primary
    color="primary"
  >
    Move to archive
  </button>
</mat-dialog-actions>
