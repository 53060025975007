import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionSpace } from '../../models/solution';
import { map, switchMap, BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, shareReplay } from 'rxjs';
import { MeasurementsType } from 'src/app/models/measurement';
import { DateInterval } from 'src/app/models/date.model';
import { endOfDay } from 'src/app/shared/utils';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { ComponentDetailComponent } from '../../shared/component-detail/component-detail.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-zx-project-component-detail',
  templateUrl: './zx-project-component-detail.component.html',
  styleUrls: ['./zx-project-component-detail.component.scss'],
  standalone: true,
  imports: [NgIf, ComponentDetailComponent, AsyncPipe],
})
export class ZxProjectComponentDetailComponent {
  measurementDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  trackingPointsDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  projectId$ = this.route.params.pipe(map((el) => el.id as string));

  componentId$ = this.route.params.pipe(map((el) => el.componentId as string));

  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );
  component$ = combineLatest([this.solutionSpace$, this.projectId$, this.componentId$]).pipe(
    switchMap(([solutionSpace, projectId, componentId]) =>
      this.projectService.getComponent(solutionSpace, projectId, componentId)
    )
  );

  componentActivities$ = combineLatest([this.projectId$, this.componentId$]).pipe(
    switchMap(([projectId, componentId]) =>
      this.projectService.getComponentActivity(projectId, componentId, 100, undefined)
    )
  );

  trackingPoints$ = combineLatest([this.projectId$, this.componentId$, this.trackingPointsDateInterval$]).pipe(
    switchMap(([projectId, componentId, dateRange]) =>
      this.projectService.getComponentDatalog(projectId, componentId, dateRange.start, endOfDay(dateRange.end))
    )
  );

  measurements$ = combineLatest([this.projectId$, this.componentId$, this.measurementDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([projectId, componentId, dateRange]) =>
      this.projectService.getComponentMeasurementsData(
        projectId,
        componentId,
        [MeasurementsType.Humidity, MeasurementsType.Temperature],
        dateRange.start,
        endOfDay(dateRange.end)
      )
    )
  );

  utilizationStatistics$ = combineLatest([this.projectId$, this.componentId$]).pipe(
    distinctUntilChanged(),
    switchMap(([projectId, componentId]) => this.projectService.getComponentStatistics(projectId, componentId)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  constructor(private route: ActivatedRoute, private projectService: ProjectsService) {}

  updateTackingPoints(newDateRange: DateInterval) {
    this.trackingPointsDateInterval$.next(newDateRange);
  }

  updateMeasurements(newDateRange: DateInterval) {
    this.measurementDateInterval$.next(newDateRange);
  }
}
