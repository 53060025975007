import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate',
  standalone: true,
})
export class FormatDatePipe implements PipeTransform {
  transform(dateValue: Date | undefined): string {
    if (dateValue === undefined) {
      return '';
    }

    return dateValue.toLocaleDateString();
  }
}
