<nav class="month__title">
  <polytech-ui-svg-button
    icon="arrow-left"
    [backing]="false"
    size="normal"
    (buttonClick)="changeMonthHandler($event, -1)"
    [propagateClick]="false"
  >
  </polytech-ui-svg-button>
  <span
    ><strong>{{ month }}</strong
    >&nbsp;{{ year }}</span
  >
  <polytech-ui-svg-button
    icon="arrow-down"
    [backing]="false"
    size="normal"
    color="white"
    (buttonClick)="changeYearHandler($event)"
  >
  </polytech-ui-svg-button>
  <polytech-ui-svg-button
    icon="arrow-right"
    [backing]="false"
    size="normal"
    (buttonClick)="changeMonthHandler($event, 1)"
    [propagateClick]="false"
  >
  </polytech-ui-svg-button>
</nav>
<section class="month__wrapper">
  <span class="day-name">MO</span>
  <span class="day-name">TU</span>
  <span class="day-name">WE</span>
  <span class="day-name">TH</span>
  <span class="day-name">FR</span>
  <span class="day-name">SA</span>
  <span class="day-name">SU</span>
  <app-ui-date-day
    *ngFor="let item of [].constructor(monthLength); let i = index"
    [ngClass]="[
      i === 0 ? startDay : '',
      isSelectedDate(i + 1) ? 'selected' : '',
      isEndpoint(i + 1) ? 'endpoint' : '',
      isRange(i + 1) ? 'range' : '',
      isRowStart(i + 1) ? 'start' : '',
      isRowEnd(i + 1) ? 'end' : ''
    ]"
    [day]="i + 1"
    [timestamp]="dayTimestamp(i + 1)"
    (clickSelect)="dateSelector($event)"
    [earliest]="earliest"
    [latest]="latest"
  >
  </app-ui-date-day>
  <section></section>
</section>
