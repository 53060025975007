import { Component, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Type } from 'class-transformer';
import { ChartDataset, ChartOptions, Point } from 'chart.js';
import 'chartjs-adapter-date-fns';
import 'chartjs-plugin-zoom';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
export class MetData {
  @Type(() => Date)
  date: Date;
  humidity: number;
  temperature: number;
  dewPoint: number;
  threshold: number;
  schock?: number;
  shockThreshold?: number;
}

@Component({
  selector: 'app-meteorology-chart',
  templateUrl: './meteorology-chart.component.html',
  styleUrls: ['./meteorology-chart.component.scss'],
  standalone: true,
  imports: [NgChartsModule, NgIf, MatButtonModule, MatIconModule],
})
export class MeteorologyChartComponent implements OnDestroy {
  private _data: MetData[] = [];
  HUMIDITY_LABEL = 'Humidity [%]';
  TEMPERATURE_LABEL = 'Temperature [°C]';
  DEW_POINT_LABEL = 'Dew Point [°C]';
  THRESHOLD = 'Threshold';
  @ViewChild(BaseChartDirective, { static: false }) lineChart: BaseChartDirective;
  @Input()
  showLegend = true;
  @Input()
  thresholdValue: number;
  @Input()
  hasHumidityThreshold?: boolean;
  @Input()
  hasTempThreshold?: boolean;
  humidityChartLabels: string[] = [];
  lineChartData: ChartDataset<'line'>[];
  options: ChartOptions<'line'> = this.setOptions();
  isZoomHidden = true;
  @Input()
  isMobile: boolean;

  @Input() set data(value: MetData[]) {
    if (value) {
      this._data = value.reverse();
      this.setLabels();
      this.setDataSet();
    }
  }

  constructor(private zone: NgZone) {}
  setLabels(): void {
    this.humidityChartLabels = this._data.map((d) => d.date.toISOString());
  }
  setDataSet() {
    const humidtidyData: Point[] = this._data.map((d) => ({ x: d.date.getTime(), y: d.humidity }));
    const dewPoints: Point[] = this._data.map((d) => ({ x: d.date.getTime(), y: d.dewPoint }));
    const temperature: Point[] = this._data.map((d) => ({ x: d.date.getTime(), y: d.temperature }));
    const threshold: Point[] = this._data.map((d) => ({ x: d.date.getTime(), y: d.threshold }));

    this.lineChartData = [
      {
        label: this.HUMIDITY_LABEL,
        fill: false,
        tension: 0.1,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',
        pointBorderColor: '#4682B4',
        pointBackgroundColor: '#4682B4',
        pointBorderWidth: 1,
        borderWidth: 2,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(46,82,B4, 0.2);',
        pointHoverBorderColor: '#4682B4',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        type: 'line',
        data: humidtidyData,
        yAxisID: 'yHumidity',
      },
      {
        label: this.TEMPERATURE_LABEL,
        fill: false,
        tension: 0.2,
        backgroundColor: '#dc2d0a',
        borderColor: '#dc2d0a',
        borderWidth: 2,
        pointBorderColor: '#dc2d0a',
        pointBackgroundColor: '#dc2d0a',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#dc2d0a',
        pointHoverBorderColor: '#dc2d0a',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        type: 'line',
        data: temperature,
        yAxisID: 'yTemperature',
      },
      {
        label: this.THRESHOLD,
        fill: false,
        tension: 0.1,
        showLine: true,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',

        borderDash: [1, 1],
        borderDashOffset: 0.0,
        pointBorderColor: '#4682B4',
        pointBackgroundColor: '#4682B4',
        borderWidth: 1.5,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#54585A',
        pointHoverBorderColor: '#54585A',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        type: 'line',
        data: threshold,
        yAxisID: 'yHumidity',
      },
      {
        label: this.DEW_POINT_LABEL,
        fill: false,
        tension: 0.1,
        backgroundColor: '#028767',
        borderColor: '#028767',
        borderCapStyle: 'butt',
        borderWidth: 2,
        pointBorderColor: '#028767',
        pointBackgroundColor: '#028767',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#028767',
        pointHoverBorderColor: '#028767',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        type: 'line',
        data: dewPoints,
        yAxisID: 'yTemperature',
      },
    ];
  }

  resetZoom(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.lineChart.chart['resetZoom']();
    this.isZoomHidden = true;
  }

  setOptions(): ChartOptions<'line'> {
    return {
      plugins: {
        zoom: {
          zoom: {
            mode: 'xy',
            onZoom: () => {
              this.isZoomHidden = false;
            },

            drag: {
              enabled: true,
              backgroundColor: 'rgba(173, 216, 230, 0.2)',
              borderColor: 'rgba(173, 216, 230, 1)',
              borderWidth: 1,
            },
          },
        },
        tooltip: {
          position: 'nearest',
          displayColors: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (context) => {
              const dataPortion = context.dataset as ChartDataset<'line'>;
              if (dataPortion) {
                switch (dataPortion.yAxisID) {
                  case 'yHumidity':
                    if (dataPortion.label === this.HUMIDITY_LABEL) {
                      return `Humidity: ${context.parsed.y}%`;
                    } else if (dataPortion.label === this.THRESHOLD) {
                      return `Humidity Threshold: ${context.parsed.y}%`;
                    }
                    break;
                  case 'yTemperature':
                    if (dataPortion.label === this.DEW_POINT_LABEL) {
                      return `Dew Point: ${context.parsed.y}°C`;
                    } else if (dataPortion.label === this.TEMPERATURE_LABEL) {
                      return `Temperature: ${context.parsed.y}°C`;
                    }
                    break;
                }
              }
              return '';
            },
          },
        },
        legend: {
          display: this.showLegend,
          position: 'bottom',
          labels: {
            color: '#54585A',
            boxWidth: 5,
            // boxHeight: 0, //0 means use fontSize
            padding: 10,
          },
        },
      },

      responsive: true,
      maintainAspectRatio: false,

      scales: {
        x: {
          type: 'time',
          bounds: 'ticks',
          ticks: {
            color: '#000000',
            font: {
              size: 12,
            },
            maxTicksLimit: 7,
            minRotation: 0,
            maxRotation: 0,
          },
          time: {
            displayFormats: {
              millisecond: 'MMM dd HH:mm:ss',
              second: 'MMM dd HH:mm:ss',
              minute: 'MMM dd HH:mm',
              hour: 'MMM dd',
              day: 'MMM dd',
              week: 'MMM dd',
              month: 'MMM',
              quarter: 'MMM',
            },
          },
        },
        yHumidity: {
          type: 'linear',
          position: 'left',
          display: true,
          title: {
            display: true,
            text: 'Humidity %',
            color: '#000000',
            font: {
              size: 15,
            },
          },
          grid: {
            display: false,
          },
          max: 100,
          beginAtZero: true,
          ticks: {
            color: '#000000',
            stepSize: 20,
          },
        },
        yTemperature: {
          type: 'linear',
          position: 'right',
          display: true,
          title: {
            display: true,
            text: 'Temperature °C',
            color: '#000000',
            font: {
              size: 15,
            },
          },
          grid: {
            display: false,
          },
          ticks: {
            color: '#000000',
            stepSize: 4,
          },
        },
      },
    };
  }

  ngOnDestroy(): void {
    if (this.lineChart) this.lineChart.ngOnDestroy();
  }
}
