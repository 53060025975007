import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InfoPageComponent } from '../../shared/info-page/info-page.component';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
  standalone: true,
  imports: [InfoPageComponent],
})
export class AdminPageComponent implements OnInit {
  activeLink = 'devices';

  links = [
    /*  { id: 'users', label: 'User management', path: 'users' },
    { id: 'groups', label: 'Groups', path: 'groups' }, */
    { id: 'devices', label: 'Device pool', path: 'devices' },
    /*  { id: 'organizations', label: 'Organizations', path: 'organizations' }, */
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.firstChild?.pathFromRoot[2].url.subscribe((seg) => {
      this.activeLink = seg[0].path;
    });
  }

  getTitle(): string {
    const link = this.links.find((l) => l.id === this.activeLink);
    return link ? link.label : '';
  }

  /* getButton(): string {
    switch (this.activeLink) {
      case 'users':
        return 'User';
      case 'groups':
        return 'Group';
      default:
        return '';
    }
  } */
}
