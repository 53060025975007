import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';
@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AngularSvgIconModule, MatButtonModule],
})
export class ArchiveDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ArchiveDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
