/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, FormsModule } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { BackendCreateProject, BackendUpdateProject, ProjectsService } from 'src/app/services/api/projects.service';
import { Group, UserService } from 'src/app/services/api/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ArchiveDialogComponent } from './archive-dialog/archive-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { ResponseObj } from 'src/app/models/errorHandling';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { InputFieldComponent } from '../../shared/input-field/input-field.component';
import { SelectFieldComponent } from '../../shared/select-field/select-field.component';
import { NgIf } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

class ProjectForm {
  public solution: string | undefined;
  initialSolution: string;
  setInitialSolution(solution: string): void {
    this.initialSolution = solution;
    this.solution = solution;
  }
  public solutionChange = new EventEmitter<string>();
  updateSolution(solutionControl: UntypedFormControl): void {
    if (typeof solutionControl.value == 'string') {
      this.solution = solutionControl.value;
      this.solutionChange.emit(this.solution);
    } else {
      this.solution = undefined;
    }
  }
  get solutionValue(): SolutionSpace {
    return this.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
  }

  public name: string;
  initialName: string;
  setInitialName(name: string): void {
    this.initialName = name;
    this.name = name;
  }
  updateName(newName: string): void {
    this.name = newName;
  }
  nameValidated(): boolean {
    return !!(this.name && this.name.length > 0);
  }

  public description: string;
  initialDescription: string;
  updateDescription(newDescription: string): void {
    this.description = newDescription;
  }
  setInitialDescription(description: string): void {
    this.initialDescription = description;
    this.description = description;
  }
  public group: string | undefined;
  initialGroup: string;
  setInitialGroup(group: string): void {
    this.initialGroup = group;
    this.group = group;
  }
  updateGroup(groupControl: UntypedFormControl): void {
    this.group = typeof groupControl.value == 'string' ? groupControl.value : undefined;
  }
  groupValidated(): boolean {
    return !!this.group;
  }

  components: {
    id: string;
    name: string;
    type: string;
    loading?: boolean;
  }[] = [];

  valid(): boolean {
    return !!(this.solution && this.name && this.name.length > 0 && this.group);
  }

  pristine(): boolean {
    return this.initialName === this.name && this.initialDescription === this.description;
  }

  asBackendCreate(): BackendCreateProject {
    return {
      solution: this.solution,
      groupId: this.group,
      name: this.name,
      description: this.description ? this.description : undefined,
    };
  }

  asBackendUpdate(): BackendUpdateProject {
    return {
      name: this.name,
      description: this.description,
    };
  }
}

@Component({
  selector: 'app-zx-project-form',
  templateUrl: './zx-project-form.component.html',
  styleUrls: ['./zx-project-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatePipe],
  standalone: true,
  imports: [
    AngularSvgIconModule,
    FormsModule,
    NgIf,
    SelectFieldComponent,
    InputFieldComponent,
    MatButtonModule,
    TranslateModule,
  ],
})
export class ZxProjectFormComponent implements OnInit, OnDestroy {
  @Input() project: zxProjectModel | undefined;
  @ViewChild('projectStepper', { static: true }) projectStepper: MatStepper;
  skipNextControl: UntypedFormControl = new UntypedFormControl();
  form: ProjectForm = new ProjectForm();
  groups: Group[];
  solution: SolutionSpace;

  origin: string;
  doubleSolutionAccess = false;
  loading = false;
  hasProject: boolean;
  private paramSubscription: Subscription;
  createProjectSubscription: Subscription;
  editProjectSubscription: Subscription;

  iconPath: string;
  navText: string;
  groupValues: { value: string; label: string }[];

  private orgSubscription: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private userService: UserService,
    private authService: AuthenticationService,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private responseHandler: ResponseHandlerService,
    private translate: TranslatePipe
  ) {
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-in.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-left`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-collapse.svg')
    );
  }

  ngOnInit(): void {
    this.hasProject = this.project !== undefined;

    if (!this.hasProject) {
      this.iconPath = '/assets/images/icon-40-close.svg';
      this.navText = 'SHARED.CANCEL';
    } else {
      this.iconPath = '/assets/images/icon-40-back.svg';
      this.navText = 'project.FORM.BACK_TO_PROJECTS';
    }

    this.orgSubscription = this.authService.getUserAccessPermissions().subscribe((canAccess) => {
      if (canAccess?.includes('utilization') && canAccess?.includes('humidity')) {
        this.doubleSolutionAccess = true;
      }
    });

    this.paramSubscription = this.route.queryParams.subscribe((el) => {
      this.origin = typeof el.origin == 'string' ? el.origin : '';

      this.solution = el.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
      this.form.setInitialSolution(this.solution);

      if (this.project) {
        this.form.setInitialName(this.project.name);
        this.form.setInitialGroup(this.project.groupId);
        if (this.project.description) this.form.setInitialDescription(this.project.description);
      }

      this.fetchGroups();
    });
  }

  ngOnDestroy(): void {
    this.orgSubscription && this.orgSubscription.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
    this.createProjectSubscription && this.createProjectSubscription.unsubscribe();
    this.editProjectSubscription && this.editProjectSubscription.unsubscribe();
  }

  get stepHeader(): string {
    return this.project ? 'project.FORM.EDIT_PROJECT_HEADER' : 'project.FORM.CREATE_PROJECT_HEADER';
  }

  clickNav(): void {
    void this.router.navigate(['/projects']);
  }

  onSubmit(): void {
    this.loading = true;
    const solution = this.doubleSolutionAccess ? (this.form.solution as SolutionSpace) || this.solution : this.solution;

    if (!this.project) {
      this.createProjectSubscription = this.projectsService
        .createProject(this.form.asBackendCreate(), solution)
        .subscribe((res) => {
          if (this.skipNextControl.value === true) {
            void this.router.navigate([this.origin]);
          } else {
            this.projectsService.getProject(this.solution, res).subscribe(
              (project) => {
                this.project = project;
                if (this.projectStepper?.selected?.completed !== undefined) {
                  this.projectStepper.selected.completed = true;
                }
                const obj: ResponseObj = {
                  title: this.translate.transform('project.FORM.SUBMIT_TITLE_CREATE'),
                  message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_CREATE', {
                    projectName: this.form.name,
                  }),
                };
                this.snackbarService.openSnackBar(obj, 'CREATE');
                this.loading = false;
              },
              (error: HttpErrorResponse) => this.responseHandler.error(error)
            );
          }
        });
    } else {
      const projectName = this.project.name;
      this.loading = true;
      this.editProjectSubscription = this.projectsService
        .editProject(this.form.asBackendUpdate(), this.project.id)
        .subscribe({
          next: () => {
            this.loading = false;
            if (this.projectStepper?.selected?.completed !== undefined) {
              this.projectStepper.selected.completed = true;
            }
            const obj: ResponseObj = {
              title: this.translate.transform('project.FORM.SUBMIT_TITLE_UPDATE'),
              message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_UPDATE', {
                projectName: projectName,
              }),
            };
            this.snackbarService.openSnackBar(obj, 'UPDATE');
            window.location.reload();
          },
          error: (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          },
        });
    }
  }

  openArchiveDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '18vh';
    dialogConfig.minWidth = '18vw';

    if (!this.project) return;

    const projectId = this.project.id;
    const projectName = this.project.name;
    const dialogRef = this.dialog.open(ArchiveDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.projectsService.archiveProject(projectId).subscribe({
          next: () => {
            this.dialog.closeAll();
            const obj: ResponseObj = {
              title: this.translate.transform('project.FORM.SUBMIT_TITLE_ARCHIVED'),
              message: this.translate.transform('project.FORM.SUBMIT_MESSAGE_ARCHIVED', {
                projectName: projectName,
              }),
            };
            this.snackbarService.openSnackBar(obj, 'ARCHIVE');
            window.location.reload();
          },
          error: (error: HttpErrorResponse) => {
            this.responseHandler.error(error);
          },
        });
      }
    });
  }

  fetchGroups(): void {
    this.userService.getGroupsForProjectCreation().subscribe((groups) => {
      this.groups = groups;
      this.groupValues = this.groups ? this.groups.map((g) => ({ value: g.id, label: g.name })) : [];

      if (this.groups.length === 1) {
        this.form.setInitialGroup(this.groups[0].id);
      }
    });
  }
}
