import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AngularSvgIconModule, MatButtonModule],
})
export class ArchiveDialogComponent {
  archiveClicked = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      componentName: string;
      archiveSubject: Subject<string>;
    }
  ) {}

  dialogTitle(): string {
    return `Move ${this.data.componentName} to archive?`;
  }

  onClick(): void {
    if (this.archiveClicked) return;
    this.archiveClicked = true;
    this.data.archiveSubject.next('pressed');
  }
}
