import { Component, ElementRef, Input } from '@angular/core';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { Router, RouterLink } from '@angular/router';
import { formatDate } from '../utils';
import { InfoMap } from 'src/app/models/info-card.models';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, NgStyle, NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

export class Interpolation {
  constructor(public str: string, public value: string) {}
}

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  providers: [TranslatePipe],
  standalone: true,
  imports: [
    MatCardModule,
    NgClass,
    ExtendedModule,
    NgIf,
    RouterLink,
    NgStyle,
    NgFor,
    AngularSvgIconModule,
    TranslateModule,
  ],
})
export class InfoCardComponent {
  @Input() title: string | Interpolation | undefined;
  @Input() description: string | undefined;
  @Input() iconUrl: string | undefined;
  @Input() infoMap?: InfoMap[];
  @Input() headerRouterLink: string | undefined;
  @Input() columns = 3;
  @Input() solution = '';
  @Input() isMapInfoCard = false;

  constructor(public element: ElementRef, private translate: TranslatePipe, private router: Router) {}

  getTitle(): string {
    if (typeof this.title === 'string') {
      return this.translate.transform(this.title) as string;
    } else if (this.title instanceof Interpolation) {
      return (this.translate.transform(this.title.str) as string).replace(/%value%/g, this.title.value);
    } else {
      return '';
    }
  }

  columnsGrid(): Record<string, unknown> {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      'grid-template-columns': [...new Array(this.columns)].map(() => '1fr').join(' '),
    };
  }

  valueIsDate(value: string | Date): boolean {
    return value instanceof Date;
  }

  navigate(path: string): void {
    void this.router.navigate([path]);
  }

  formatInfoDate(date: string | Date): string {
    if (date instanceof Date) return formatDate(date);
    return '';
  }
}
