import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'formatting',
  standalone: true,
})
export class FormattingPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): string | null {
    return this.sanitize(this.format(value));
  }

  format(str: string): string {
    //return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
    return str;
  }

  sanitize(str: string): string | null {
    return this.sanitizer.sanitize(SecurityContext.HTML, str);
  }
}
