import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { contextEnum, ResponseObj } from 'src/app/models/errorHandling';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';

enum snackbarIcon {
  archive = '../../../../assets/icons/snackbar/archive.svg',
  create = '../../../../assets/icons/snackbar/create.svg',
  update = '../../../../assets/icons/snackbar/update.svg',
  bad_gateway = '../../../../assets/icons/snackbar/bad-gateway.svg',
  no_access = '../../../../assets/icons/snackbar/no-access.svg',
  not_authorized = '../../../../assets/icons/snackbar/not-authorized.svg',
  acknowledge = '../../../../assets/icons/snackbar/acknowledged.svg',
  error = '../../../../assets/icons/snackbar/error.svg',
  be_error = '../../../../assets/icons/snackbar/be-error.svg',
  loading = '../../../../assets/icons/snackbar/loading.svg',
}
@Component({
  selector: 'app-snack-bar-template',
  templateUrl: './snack-bar-template.component.html',
  styleUrls: ['./snack-bar-template.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, MatIconModule, TranslateModule],
})
export class SnackBarTemplateComponent implements OnInit {
  constructor(
    private snackBarRef: MatSnackBarRef<SnackBarTemplateComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      responseObject: ResponseObj;
      type: 'ARCHIVE' | 'CREATE' | 'UPDATE' | 'ERROR' | 'ACKNOWLEGDE' | 'LOADING';
    }
  ) {}
  ngOnInit(): void {}

  getIcon(): { icon: string } {
    if (this.data.type === 'ERROR') {
      switch (this.data.responseObject.context) {
        case contextEnum.BAD_GATEWAY:
          return { icon: snackbarIcon.bad_gateway };
        case contextEnum.NOT_AUTHORIZED:
          return { icon: snackbarIcon.not_authorized };
        case contextEnum.NO_ACCESS:
          return { icon: snackbarIcon.no_access };
        case contextEnum.FE_ERROR:
          return { icon: snackbarIcon.error };
        case contextEnum.BE_ERROR:
          return { icon: snackbarIcon.be_error };
      }
    } else if (this.data.type === 'ARCHIVE') {
      return { icon: snackbarIcon.archive };
    } else if (this.data.type === 'CREATE') {
      return { icon: snackbarIcon.create };
    } else if (this.data.type === 'UPDATE') {
      return { icon: snackbarIcon.update };
    } else if (this.data.type === 'ACKNOWLEGDE') {
      return { icon: snackbarIcon.acknowledge };
    } else if (this.data.type === 'LOADING') {
      return { icon: snackbarIcon.loading };
    }
    // not reacheable
    return { icon: '' };
  }

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
