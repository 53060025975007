import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewEncapsulation,
  Output,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { DateConversion } from '../../shared/helpers/static/date-conversion';
import { PolytechUiDateDayComponent } from '../date-day/date-day.component';
import { NgFor, NgClass } from '@angular/common';
import { PolytechUiSvgButtonComponent } from '../../svg-button/svg-button.component';
@Component({
  selector: 'polytech-ui-date-month',
  templateUrl: './date-month.component.html',
  styleUrls: ['./date-month.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PolytechUiSvgButtonComponent, NgFor, PolytechUiDateDayComponent, NgClass],
})
export class PolytechUiDateMonthComponent {
  public selectedDate: Date = new Date();
  private _sandboxedViewDate = new Date();
  private _viewDate: Date = new Date();

  @Output() currentSelection: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() earliest = -Infinity;
  @Input() latest: number = new Date().getTime();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get startDay(): string {
    const day: number = DateConversion.monthStartDayFromDate(this._sandboxedViewDate) - 1;
    return `day${day}`;
  }

  get monthLength(): number {
    return DateConversion.monthLength(this._viewDate);
    //        return new Date(this._viewDate.getFullYear(), this._viewDate.getMonth() + 1, 0).getDate() ;
  }

  get month(): string {
    const month = this._viewDate.toLocaleDateString('en-gb', {
      month: 'short',
      timeZone: localStorage.getItem('timezone') || '',
    });
    return month;
  }

  get year(): string {
    const year = this._viewDate.toLocaleDateString('en-gb', {
      year: 'numeric',
      timeZone: localStorage.getItem('timezone') || '',
    });
    return year;
  }

  get date(): number {
    const day = this._viewDate.toLocaleDateString('en-gb', {
      day: 'numeric',
      timeZone: localStorage.getItem('timezone') || '',
    });
    return parseInt(day, 10);
  }

  set currentDate(timestamp: number) {
    const day = DateConversion.dayStartFromEpoch(timestamp);

    this._viewDate = new Date(day);
    this.selectedDate = new Date(day);
    this._sandboxedViewDate = new Date(day);

    requestAnimationFrame(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  dayTimestamp(day: number): number {
    const selected = DateConversion.setDay(this._sandboxedViewDate, day);
    return DateConversion.getTime(selected);
  }

  isSelectedDate(date: number): boolean {
    const selected = DateConversion.setDay(this._viewDate, date);
    return DateConversion.sameDay(this.selectedDate, selected);
  }

  isEndpoint(day: number): boolean {
    const selected = DateConversion.setDay(this._viewDate, day);
    const ts = DateConversion.getTime(selected);
    return this.earliest === ts || this.latest === ts;
  }

  isRange(day: number): boolean {
    const selected = DateConversion.setDay(this._viewDate, day);
    const ts = DateConversion.getTime(selected);

    if (this.latest === Infinity) {
      return ts >= this.earliest && ts <= DateConversion.getTime(this.selectedDate);
    } else if (this.earliest === -Infinity) {
      return ts >= DateConversion.getTime(this.selectedDate) && ts <= this.latest;
    }
    return ts >= this.earliest && ts <= Math.min(this.latest, DateConversion.getTime(this.selectedDate));
  }

  isRowStart(day: number): boolean {
    const startDay: number = DateConversion.monthStartDayFromDate(this._sandboxedViewDate);
    return (startDay + day - 2) % 7 === 0 || day === 1;
  }

  isRowEnd(day: number): boolean {
    const startDay: number = DateConversion.monthStartDayFromDate(this._sandboxedViewDate);

    return (startDay + day - 1) % 7 === 0 || day === this.monthLength;
  }

  changeMonthHandler(event: any, direction: number) {
    this._viewDate = new Date(DateConversion.addMonths(this._viewDate, direction));
    this._sandboxedViewDate = new Date(this._viewDate);
  }

  changeYearHandler(event: any) {}

  dateSelector(day: number) {
    const pickeddDate = DateConversion.setDay(this._viewDate, day);
    this.selectedDate = new Date(DateConversion.dayStartFromDate(pickeddDate));
    this.currentSelection.emit(this.selectedDate);
  }
}
