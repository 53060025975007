<nav class="button__wrapper" [ngClass]="size" #buttonRef>
  <a
    *ngIf="!!link && !isExternalUrl(link)"
    [routerLink]="disabled ? null : link"
    [class.disabled]="disabled ? true : null"
    routerLinkActive="routeActive"
    [ngClass]="{ isInactive: (inactive$ | async) || disabled }"
    role="button"
    class="button__wrapper"
    (click)="clickHandler($event)"
    [id]="componentId !== undefined ? componentId : ''"
  >
    <svg
      class="svg__icon"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      overflow="hidden"
      width="100%"
      height="100%"
      enable-background="new 0 0 60 60"
      xml:space="preserve"
      role="button"
    >
      <rect *ngIf="backing" height="60" width="60" class="active__highlight" />
      <use attr.xlink:href="{{ svgPath }}#{{ icon }}" class="{{ color }}"></use>
    </svg>
  </a>

  <a
    *ngIf="isExternalUrl(link)"
    [class.disabled]="disabled ? true : null"
    [href]="disabled ? null : link"
    target="_blank"
    class="button__wrapper"
    role="button"
    [id]="componentId !== undefined ? componentId : ''"
  >
    <svg
      class="svg__icon"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      overflow="hidden"
      width="100%"
      height="100%"
      enable-background="new 0 0 60 60"
      xml:space="preserve"
      role="button"
    >
      <rect *ngIf="backing" height="60" width="60" class="active__highlight" />
      <use attr.xlink:href="{{ svgPath }}#{{ icon }}" class="{{ color }}"></use>
    </svg>
  </a>

  <button
    *ngIf="!link"
    (click)="clickHandler($event)"
    [ngClass]="{ isInactive: (uiInactive$ | async) || disabled }"
    class="button__wrapper"
    [id]="componentId !== undefined ? componentId : ''"
  >
    <svg
      class="svg__icon"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      overflow="hidden"
      width="100%"
      height="100%"
      enable-background="new 0 0 60 60"
      xml:space="preserve"
      role="button"
    >
      <rect *ngIf="backing" height="60" width="60" class="active__highlight" />
      <use attr.xlink:href="{{ svgPath }}#{{ icon }}" class="{{ color }}"></use>
    </svg>
  </button>

  <app-ui-click-ripple color="dark" #rippleRef></app-ui-click-ripple>
</nav>
