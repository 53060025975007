import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgIf, NgClass } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    AngularSvgIconModule,
    NgClass,
    ExtendedModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class SearchFieldComponent implements OnInit {
  @Output() keyUp = new EventEmitter<string>();
  @Output() focused = new EventEmitter();
  @Input() placeHolder: string;
  @Input() disabled = false;
  typing = false;
  isFocused = false;

  searchControl = new UntypedFormControl();

  constructor() {}

  ngOnInit(): void {
    if (this.disabled) this.searchControl.disable();
  }

  setValue(search: string): void {
    this.searchControl.setValue(search);
  }

  notify(): void {
    this.typing = true;
    const search: string | undefined =
      typeof this.searchControl.value == 'string' && this.searchControl.value.length > 0
        ? this.searchControl.value //this.searchControl.value.toLocaleLowerCase()
        : undefined;
    if (search !== undefined) {
      this.keyUp.emit(search);
    } else {
      this.typing = false;
      this.keyUp.emit('');
    }
  }

  cleanupSearch() {
    this.keyUp.emit('');
    this.searchControl.setValue('');
    this.typing = false;
  }

  focusNotify(): void {
    this.isFocused = true;
    this.focused.emit();
  }
}
