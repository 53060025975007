import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxModule } from '@angular/material/checkbox';
import { Sort, MatSortModule } from '@angular/material/sort';
import { FormatCellPipe } from '../pipes/format-cell-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatTableModule } from '@angular/material/table';
import { NgIf, NgFor, NgClass } from '@angular/common';

export type TableCell = {
  iconLeading?: string;
  linkCallback?: boolean;
  menus?: { value: string; label: string }[];
  routerLink?: string;
  tooltipText?: string;
  value?: string | number | Date;
  value2?: string | number | undefined;
};

export type TableRow = Record<string, TableCell>;

export interface TableColumn {
  key: string;
  name: string;
  indicatorIcon?: boolean;
  isSortable?: boolean;
  isSticky?: boolean;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    NgFor,
    NgClass,
    ExtendedModule,
    AngularSvgIconModule,
    MatTooltipModule,
    MatPaginatorModule,
    TranslateModule,
    FormatCellPipe,
  ],
})
export class TableComponent implements OnChanges {
  @Input() bulkEnabled = false;
  @Input() set dataSource(tableRows: TableRow[]) {
    this._dataSource = tableRows || [];
    this.selectedIndex = -1;
    this.selectedItems.clear();
    this.setSelectedStates();
  }
  @Input() pageSize = 50;
  @Input() pageSizeOptions: number[] = [50, 100, 150, 200];
  @Input() selectedPageIndex = 1;
  @Input() showSum? = false;
  @Input() set tableCols(tableCols: TableColumn[]) {
    const columnKeys = tableCols.map(({ key }) => key);
    this.tableColumns = tableCols;
    this.columnKeys = columnKeys;
  }
  @Input() totalItems: number | undefined = undefined;
  @Output() rowClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();
  @Output() linkClicked: EventEmitter<TableRow> = new EventEmitter<TableRow>();
  @Output() paginationChanged: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() selectionChanged: EventEmitter<TableRow[]> = new EventEmitter<TableRow[]>();
  @Output() sortingChanged: EventEmitter<Sort> = new EventEmitter<Sort>();

  _dataSource: TableRow[] = [];
  tableColumns: TableColumn[] = [];
  selectedIndex: number;
  columnKeys: string[] = [];
  isAllSelected = false;
  isAnySelected = false;
  selectedItems: Set<number> = new Set<number>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableCols) {
      if (this.bulkEnabled) {
        this.columnKeys.unshift('select');
      }
    } else if (changes.bulkEnabled) {
      if (this.bulkEnabled) {
        if (!this.columnKeys.includes('select')) {
          this.columnKeys.unshift('select');
        }
      } else {
        if (this.columnKeys.includes('select')) {
          const indexToRemove: number = this.columnKeys.indexOf('select');
          this.columnKeys.splice(indexToRemove, 1);
        }
      }
    }
  }

  cellClicked(selectedRow: TableRow, index: number): void {
    if (index === this.selectedIndex) {
      this.selectedIndex = -1;
    } else {
      this.selectedIndex = index;
    }
    this.rowClicked.emit(selectedRow);
  }

  _linkCallback(e: Event, selectedRow: TableRow): void {
    this.linkClicked.emit(selectedRow);
    e.preventDefault();
  }

  announceSortChange(event: Sort) {
    this.sortingChanged.emit(event);
  }

  handlePageEvent(event: PageEvent): void {
    const zeroOffsetPageEvent: PageEvent = event;
    if (event.pageIndex !== undefined) {
      zeroOffsetPageEvent.pageIndex = event.pageIndex + 1;
    }
    if (event.previousPageIndex !== undefined) {
      zeroOffsetPageEvent.previousPageIndex = event.previousPageIndex + 1;
    }
    this.paginationChanged.emit(zeroOffsetPageEvent);
  }

  masterToggle(): void {
    if (this.isAllSelected || this.isAnySelected) {
      this.selectedItems.clear();
    } else {
      for (let index = 0; index < this._dataSource.length; index++) {
        this.selectedItems.add(index);
      }
    }

    this.setSelectedStates();
    this._selectionChanged();
  }

  toggle(index: number): void {
    if (this.selectedItems.has(index)) {
      this.selectedItems.delete(index);
    } else {
      this.selectedItems.add(index);
    }

    this.setSelectedStates();
    this._selectionChanged();
  }

  private setSelectedStates() {
    this.isAllSelected = this.selectedItems.size === this._dataSource.length;
    this.isAnySelected = this.selectedItems.size > 0;
  }

  _selectionChanged(): void {
    this.selectionChanged.emit([...this.selectedItems].map((index) => this._dataSource[index]));
  }
}
