import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterIntervalOption, SelectableRangeOption } from '../filter.component';
import { TranslateModule } from '@ngx-translate/core';
import { NumberRangeInputComponent } from '../../number-range-input/number-range-input.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FilterOptionComponent } from '../../filter-option/filter-option.component';

export type DaysInStateFilterState = {
  selectedUtilizationState: string;
  selectedIntervalOption?: FilterIntervalOption;
};

@Component({
  selector: 'app-filter-days-in-state',
  templateUrl: './filter-days-in-state.component.html',
  styleUrls: ['./filter-days-in-state.component.scss'],
  standalone: true,
  imports: [
    FilterOptionComponent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    NumberRangeInputComponent,
    TranslateModule,
  ],
})
export class FilterDaysInStateComponent implements OnInit {
  @Input() utilizationOptions: SelectableRangeOption[];
  @Input() selectedState: DaysInStateFilterState;

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<DaysInStateFilterState> = new EventEmitter<DaysInStateFilterState>();

  selectedUtilizationState = '';
  currentFilterIntervalOption: FilterIntervalOption | undefined;

  ngOnInit(): void {
    if (this.selectedState) {
      this.selectedUtilizationState = this.selectedState.selectedUtilizationState;
      this.currentFilterIntervalOption = this.selectedState.selectedIntervalOption;
    }
  }

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(filterIntervalOption: FilterIntervalOption) {
    this.currentFilterIntervalOption = filterIntervalOption;
    this.optionChange.emit({
      selectedUtilizationState: this.selectedUtilizationState,
      selectedIntervalOption: filterIntervalOption,
    });
  }

  onUtilizationStateChange() {
    this.optionChange.emit({
      selectedUtilizationState: this.selectedUtilizationState,
      selectedIntervalOption: this.currentFilterIntervalOption,
    });
  }
}
