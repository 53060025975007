import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PolytechUiService } from '../shared/services/polytech-ui.service';
import { uiState } from '../shared/interfaces/ui-state.interface';
import { PolytechUiClickRippleComponent } from '../click-ripple/click-ripple.component';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'polytech-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NgClass, PolytechUiClickRippleComponent, AsyncPipe],
})
export class PolytechUiButtonComponent implements OnInit {
  @ViewChild('rippleRef', { read: ElementRef }) rippleRef: ElementRef;
  @ViewChild('buttonRef', { read: ElementRef }) buttonRef: ElementRef;

  @Input() buttonType = '';
  @Input() disabled: boolean | null = false;
  @Input() secondary = false;

  @Output() buttonAction = new EventEmitter<MouseEvent>();

  public inactive$: Observable<boolean>;
  public uiInactive$: Observable<boolean>;

  constructor(private polytechUiService: PolytechUiService) {}

  ngOnInit() {
    this.inactive$ = this.polytechUiService.uiState$.pipe(
      map((state: uiState) => this.polytechUiService.blockedNavigationState(state.value))
    );
    this.uiInactive$ = this.polytechUiService.uiState$.pipe(
      map((state: uiState) =>
        this.polytechUiService.blockedUIState(state.value, <HTMLElement>this.buttonRef?.nativeElement)
      )
    );
  }

  onClick(event: MouseEvent): void {
    this.buttonAction.emit(event);
  }
}
