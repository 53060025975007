<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-active.svg"></svg-icon>
  {{ dialogTitle() }}
</h2>

<h3>
  The component was successfully created. It will now start collecting data and can be accessed in the components view
</h3>
<p *ngIf="!isMobile()">
  Data collection is dependent on the "device" having access to GSM. See "user" manual for more details on connection.
</p>
<mat-dialog-actions>
  <button id="btn-create-another-component" mat-flat-button [ngClass]="'btn-grey'" [mat-dialog-close]="'another'">
    Create another
  </button>
  <button id="btn-configure-component" *ngIf="!isMobile()" mat-flat-button [mat-dialog-close]="'configure'">
    Configure component
  </button>
  <button id="btn-creation-done" mat-flat-button [mat-dialog-close]="'done'" mat-primary color="primary">Done</button>
</mat-dialog-actions>
