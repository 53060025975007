import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';

@Component({
  selector: 'app-archive-components',
  templateUrl: './archive-components.component.html',
  styleUrls: ['./archive-components.component.scss'],
  standalone: true,
  imports: [MatDialogModule, AngularSvgIconModule, MatButtonModule],
})
export class ArchiveComponentsComponent {
  archiveClicked = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      numComponents: number;
      archiveSubject: Subject<string>;
    }
  ) {}

  dialogTitle(): string {
    const message =
      this.data.numComponents === 1
        ? `Move ${this.data.numComponents} component to archive?`
        : `Move ${this.data.numComponents} components to archive?`;
    return message;
  }

  onClick(): void {
    if (this.archiveClicked) return;
    this.archiveClicked = true;
    this.data.archiveSubject.next('pressed');
  }
}
