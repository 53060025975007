/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { TranslatePipe } from '@ngx-translate/core';
import { Subject, Subscription, skip, switchMap } from 'rxjs';
import { ComponentState } from 'src/app/models/component';
import { SolutionSpace } from 'src/app/models/solution';
import { formatDate } from 'src/app/shared/utils';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { BackendComponent } from 'src/app/services/api/components.service';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { DeviceStatus } from 'src/app/models/device';
import { ComponentHealth } from 'src/app/models/component-health';
import { ClusterFormatter } from '../../shared/cluster-formatter';
import { IMapData, IPin, MapViewComponent } from '../../shared/map-view/map-view.component';
import { UtilizationMapIcons } from '../../models/map/utilizationType';
import { HumidityMapIcons } from '../../models/map/humidityType';
import { IPopupData } from '../../shared/map-view/factories/popups/Interfaces';
import { MapUtil } from 'src/app/shared/map-util';
import { InfoMap } from 'src/app/models/info-card.models';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { ResponseObj } from 'src/app/models/errorHandling';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { componentToTableRow } from 'src/app/models/transforms';
import { utilFilter } from 'src/app/models/filter';
import { PopupType } from 'src/app/shared/map-view/factories/popups/FactoryPopup';
import { PageResponse } from 'src/app/models/page-response';
import { TableComponent } from '../../shared/table/table.component';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { InfoCardComponent } from '../../shared/info-card/info-card.component';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'app-zx-project-details-overview',
  templateUrl: './zx-project-details-overview.component.html',
  styleUrls: ['./zx-project-details-overview.component.scss'],
  providers: [TranslatePipe],
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    FlexModule,
    InfoCardComponent,
    MatSidenavModule,
    NgClass,
    ExtendedModule,
    MatButtonModule,
    MatMenuModule,
    AngularSvgIconModule,
    MatIconModule,
    NgFor,
    TableComponent,
    MapViewComponent,
  ],
})
export class ZxProjectDetailsOverviewComponent implements AfterViewInit, OnInit, OnDestroy {
  clusterPoints: GeoJSON.FeatureCollection;
  dataSource: TableRow[] = [];
  displatedDateRange: string;
  displayedColumns: TableColumn[] = [
    { key: 'componentName', name: 'Component name' },
    { name: '', key: 'isCurrentlyInProject' },
    { name: 'Incidents', key: 'incident' },
  ];
  displayedCompFilters = { text: '', icon: '' };
  infoMap: Array<InfoMap>;
  isComponentLoaded = false;
  isDrawerOpen = true;
  measurements: Array<{ value: string; key: utilFilter; icon: string }> = [];
  markerData: Array<IMapData>;
  project: zxProjectModel;
  solution: SolutionSpace;

  private dateRanges = [
    { label: ' Last 14 days', value: '14' },
    { label: 'Last 30 days', value: '30' },
    { label: 'Last 3 months', value: '90' },
    { label: 'Last 6 months', value: '180' },
    { label: 'All Time', value: 'ALL' },
  ];
  private mapDataSub: Subject<{ components: BackendComponent[] }> = new Subject<{
    components: BackendComponent[];
  }>();
  private mapSubscriber: Subscription;
  private projectId = '';
  private components: Array<BackendComponent>;
  private routeSub: Subscription | undefined;

  private _clusterFormat: ClusterFormatter = new ClusterFormatter();
  private _positionData: Array<IPin> = [];

  private activeSelectedTableRowId: string | null = null;
  private componentsSub: Subscription;

  constructor(
    private translate: TranslatePipe,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private responseHandler: ResponseHandlerService,
    private mobileService: ResponsiveService,
    private snackBar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.displatedDateRange = this.dateRanges[4].value;
    this.routeSub = this.route.params.subscribe((el) => {
      if (el.utilorhumid === 'utilization') {
        this.measurements = [
          { value: 'All', key: utilFilter.all, icon: '/assets/icons/utilization-device.svg' },
          { value: 'Available', key: utilFilter.available, icon: '/assets/icons/check-mark.svg' },
          { value: 'In use', key: utilFilter.inUse, icon: '/assets/icons/icon-16-in-use.svg' },
          { value: 'Return', key: utilFilter.inReturn, icon: '/assets/icons/icon-16-orange-return.svg' },
          { value: 'Service', key: utilFilter.inService, icon: '/assets/icons/icon-16-service-grey.svg' },
        ];
        this.displayedCompFilters.text = this.measurements[0].value;
        this.displayedCompFilters.icon = this.measurements[0].icon;

        this.displayedColumns.push({ key: 'utilization', name: 'Utilization' });
      } else {
        this.displayedColumns.push({ key: 'humidity', name: 'Humidity' });
      }

      if (el.utilorhumid === 'humidity') {
        this.solution = SolutionSpace.Humidity;
      } else {
        this.solution = SolutionSpace.Utilization;
      }
      this.projectId = String(el['id']);

      this.componentsSub = this.projectsService
        .getProject(this.solution, this.projectId)
        .pipe(
          switchMap((project) => {
            this.project = project;
            this.project.components = [];
            this.infoMap = this.getInfoMap();
            return this.projectsService.getComponents(this.solution, this.project.id, {
              states: [
                ComponentState.Available,
                ComponentState.InReturn,
                ComponentState.InUse,
                ComponentState.InService,
              ],
            });
          })
        )
        .subscribe(this.getComponentsSubscriber());
    });

    this.mapSubscriber = this.mapDataSub.subscribe((data) => {
      const positionData: Array<IPin> = [];

      for (const entry of data.components) {
        const dataset: IPin | null = (({ lat, lng }) => ({ lat, lng }))(<IPin>entry?.position);

        if (dataset !== null && dataset !== undefined) {
          dataset.status =
            this.solution === SolutionSpace.Utilization
              ? UtilizationMapIcons[entry.state]
              : HumidityMapIcons[+entry.componentHealth.activeHumidityIncident];

          dataset.statusInt =
            this.solution === SolutionSpace.Utilization
              ? <number>entry.state
              : +entry.componentHealth.activeHumidityIncident;
        }
        positionData.push(dataset);
      }

      const shouldRenderCluster = !MapUtil.areEqual(this._positionData, positionData);
      if (shouldRenderCluster) {
        this._positionData = positionData;
        this._clusterFormat.clusters.pipe(skip(1)).subscribe((data: GeoJSON.FeatureCollection) => {
          this.clusterPoints = data;
        });
        this._clusterFormat.format(this._positionData);
      }

      this.markerData = [
        {
          image: 'assets/images/map/map-pin-clustered-tracking.png',
          position: positionData,
          clustered: true,
          popupFunction: (renderer: Renderer2, id: number) =>
            MapUtil.getPopup(renderer, PopupType.Project, this.solution, <IPopupData>{
              ...data.components?.[id],
              type: {
                id: data.components?.[id].componentTypeId,
                name: data.components?.[id].componentTypeName,
                description: data.components?.[id].componentTypeDescription,
              },
            }),
        },
      ];
    });
  }

  ngAfterViewInit(): void {}

  onRowClicked(selectedTableRow: TableRow): void {
    const componentSelected = this.components.find((navComponent) => navComponent.id === selectedTableRow.id.value);

    if (!componentSelected) {
      const obj: ResponseObj = {
        title: 'Error',
        message: 'Selected element does not exist, please refresh the page.',
      };
      this.snackBar.openSnackBar(obj, 'ERROR');
      return;
    }

    if (!componentSelected.position) {
      this.setMapData(this.components);
      return;
    }

    this.activeSelectedTableRowId =
      selectedTableRow.id.value === this.activeSelectedTableRowId ? null : (selectedTableRow.id.value as string);

    if (this.activeSelectedTableRowId) {
      this.setMapData([componentSelected]);
    } else {
      this.setMapData(this.components);
    }
  }

  filterByUsage(measurement: { value: string; key: utilFilter; icon: string }) {
    let state: undefined | Array<ComponentState> = undefined;
    switch (measurement.key) {
      case utilFilter.all: {
        state = [ComponentState.InReturn, ComponentState.InService, ComponentState.InUse, ComponentState.Available];
        break;
      }
      case utilFilter.available: {
        state = [ComponentState.Available];
        break;
      }
      case utilFilter.inUse: {
        state = [ComponentState.InUse];
        break;
      }
      case utilFilter.inReturn: {
        state = [ComponentState.InReturn];
        break;
      }
      case utilFilter.inService: {
        state = [ComponentState.InService];
        break;
      }
    }
    this.displayedCompFilters.text = measurement.value;
    this.displayedCompFilters.icon = measurement.icon;

    this.componentsSub = this.projectsService
      .getComponents(this.solution, this.project.id, {
        states: state,
      })
      .subscribe(this.getComponentsSubscriber());
  }

  getComponentsSubscriber() {
    return {
      next: (components: PageResponse<BackendComponent>) => {
        this.dataSource = components.items.map((component) => componentToTableRow(this.solution, component));
        this.components = components.items;
        this.isComponentLoaded = true;
        this.setMapData(this.components);
      },
      error: (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
      },
    };
  }

  convertComponentHeathToStaus(componentHeath: ComponentHealth): DeviceStatus {
    if (componentHeath.activeBatteryLowIncident) {
      return DeviceStatus.AlertBatteryLow;
    }
    if (componentHeath.activeShockIncident) {
      return DeviceStatus.WarningAccelerometerShock;
    }
    if (componentHeath.activeHumidityIncident && this.solution === 'humidity') {
      return DeviceStatus.WarningHumidityHigh;
    }
    if (componentHeath.activeNoCommIncident) {
      return DeviceStatus.AlertNoCommunication;
    }
    return DeviceStatus.Ok;
  }

  getInfoMap(): { label: string; value: string | Date; icon?: string }[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const infoMap: Array<InfoMap> = [
      {
        label:
          this.solution === SolutionSpace.Utilization
            ? this.translate.transform('project.componentsAvailable')
            : this.translate.transform('project.componentsAmount'),
        value:
          this.solution === SolutionSpace.Utilization
            ? this.project.availableCount.toString()
            : this.project.componentCount.toString(),
        icon:
          this.solution === SolutionSpace.Utilization
            ? '/assets/icons/check-mark.svg'
            : '/assets/icons/icon-40-components.svg',
      },
      {
        label:
          this.solution === SolutionSpace.Utilization
            ? this.translate.transform('project.componentsInUse')
            : this.translate.transform('project.humidity'),
        value:
          this.solution === SolutionSpace.Utilization
            ? this.project.inUseCount.toString()
            : this.project.activeHumidityIncidentCount.toString(),
        icon:
          this.solution === SolutionSpace.Utilization
            ? '/assets/icons/icon-16-in-use.svg'
            : '/assets/icons/icon-16-humidity-incidents.svg',
      },
    ];
    if (this.solution === SolutionSpace.Utilization) {
      infoMap.push({
        label: this.translate.transform('project.componentsInReturn'),
        value: this.project.inReturnCount.toString(),
        icon: '/assets/icons/icon-16-orange-return.svg',
      });
      infoMap.push({
        label: this.translate.transform('project.componentsInService'),
        value: this.project.inServiceCount.toString(),
        icon: '/assets/icons/icon-16-service-grey.svg',
      });
    }

    const additionalInfoMap = [
      {
        label: this.translate.transform('project.created'),
        value: formatDate(this.project.created),
      },
      {
        label: this.translate.transform('project.group'),
        value: this.project.groupName,
      },
    ];
    infoMap.push(...additionalInfoMap);
    return infoMap;
  }

  toggle(drawer: MatDrawer): void {
    this.isDrawerOpen = !this.isDrawerOpen;
    void drawer.toggle();
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
    this.mapSubscriber.unsubscribe();
    this.componentsSub.unsubscribe();
  }

  isMobile(): boolean {
    return this.mobileService.isMobile();
  }

  private setMapData(components: BackendComponent[]): void {
    this.mapDataSub.next({
      components: components.filter((component) => component.position),
    });
  }
}
