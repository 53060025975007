import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'app-set-project-dialog',
  templateUrl: './set-project-dialog.component.html',
  styleUrls: ['./set-project-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatButtonModule,
  ],
})
export class SetProjectDialogComponent {
  addClicked = false;
  formGroup = new UntypedFormGroup({
    projects: new UntypedFormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      numComponents: number;
      selectedProject: string;
      addSubject: Subject<string>;
      projects: { projectId: string; projectName: string }[];
    }
  ) {}

  noProjectSelected(): boolean {
    return !this.formGroup.get('projects')?.dirty;
  }

  onClick(): void {
    if (this.addClicked) return;
    this.addClicked = true;
    this.data.selectedProject = this.formGroup.get('projects')?.value as string;
    this.data.addSubject.next('pressed');
  }
}
