/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { ComponentState, ZXComponent } from 'src/app/models/component';
import { SolutionSpace } from '../../models/solution';
import { Interpolation } from 'src/app/shared/info-card/info-card.component';
import { formatDate, formatDashesToColon } from '../utils';
import {
  getHumidityIcon,
  getHumidityTitle,
  parseComponentHealthToIcon,
  parseComponentHealtToString,
} from 'src/app/models/component-health';
import { InfoCardComponent } from '../info-card/info-card.component';

@Component({
  selector: 'app-component-detail-card',
  templateUrl: './component-detail-card.component.html',
  styleUrls: ['./component-detail-card.component.scss'],
  standalone: true,
  imports: [InfoCardComponent],
})
export class ComponentDetailCardComponent implements OnInit {
  @Input() solution: SolutionSpace;
  @Input() component: ZXComponent;
  @Input() isMobile: boolean;
  inofCardData: {
    label: string;
    value: string | Date;
    icon?: string;
    path?: string;
  }[] = [];
  constructor() {}

  ngOnInit(): void {
    this.getInfoMap();
  }

  getColumns(): number {
    return this.isMobile ? 2 : 3;
  }
  getInfoMap(): void {
    if (this.component === undefined) {
      this.inofCardData = [];
      return;
    }

    if (this.solution == SolutionSpace.Humidity) {
      this.inofCardData = [
        {
          label: 'component.humidity',
          value: this.component.type.isHumidityWarningActive
            ? `${Math.round(
                this.component.componentHealth.activeHumidityIncident
                  ? this.component.componentHealth.activeHumidityIncidentValue
                  : this.component.componentHealth.latestHumidityMeasurement
              )}%<b> / ${Math.round(this.component.type.humidityThreshold)}%</b>`
            : `${Math.round(
                this.component.componentHealth.activeHumidityIncident
                  ? this.component.componentHealth.activeHumidityIncidentValue
                  : this.component.componentHealth.latestHumidityMeasurement
              )}%`,
          //icon: parseComponentHealthToIcon(this.component.componentHealth, this.solution),
        },
        {
          label: 'component.type',
          value: typeof this.component.type == 'string' ? this.component.type : this.component.type.name,
        },
        {
          label: 'component.project',
          value: this.component.projectName,
          path: `/projects/humidity/${this.component.projectId}/overview`,
        },
      ];
      if (!this.component.archived) {
        const additionalData = [
          {
            label: 'component.device',
            value: formatDashesToColon(this.component.deviceIdentifier),
          },
          {
            label: 'component.deviceType',
            value: this.component.deviceType,
          },
          {
            label: 'component.lastCommunication',
            value: formatDate(this.component.componentHealth.lastCommunication),
          },
        ];
        this.inofCardData.push(...additionalData);
        /*
        
          label: 'component.batteryLevel',
          value: formatDeviceBatteryLevel(this.component.batteryLevel),
        },
        */
      }
      return;
    } else if (this.solution == SolutionSpace.Utilization) {
      this.inofCardData = [
        {
          label: 'Component Heath',
          value: parseComponentHealtToString(this.component.componentHealth),
          icon: parseComponentHealthToIcon(this.component.componentHealth, this.solution), //'/assets/icons/icon-16-condition.svg',
        },
        {
          label: 'component.type',
          //value: typeof this.component.type == 'string' ? this.component.type : this.component.type.name,
          value:
            typeof this.component.type == 'string'
              ? this.component.type
              : this.component.type && this.component.type.description
              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `${this.component.type.name} - ${this.component.type.description}`
              : this.component.type.name,
        },
        {
          label: 'component.project',
          value: this.component.projectName,
          path: `/projects/utilization/${this.component.projectId}/overview`,
        },
      ];
      if (!this.component.archived) {
        const additionalData = [
          {
            label: 'component.device',
            value: formatDashesToColon(this.component.deviceIdentifier),
          },
          {
            label: 'component.deviceType',
            value: this.component.deviceType,
          },
          {
            label: 'component.lastCommunication',
            value: formatDate(this.component.componentHealth.lastCommunication),
          },
        ];
        this.inofCardData.push(...additionalData);
        /*
        
          label: 'component.batteryLevel',
          value: formatDeviceBatteryLevel(this.component.batteryLevel),
        },
        */
      }
      return;
    } else {
      this.inofCardData = [];
    }
    return;
  }

  getIcon(): string {
    if (!this.component) return '';

    if (this.component.archived) {
      return '/assets/icons/icon-16-archive.svg';
    }

    if (this.solution === SolutionSpace.Humidity) {
      return getHumidityIcon(this.component.componentHealth);
    }
    // utilization

    switch (this.component.state) {
      case ComponentState.Archived:
        return '/assets/icons/icon-16-archive.svg';
      case ComponentState.Available:
        return '/assets/images/icon-40-available.svg';
      case ComponentState.InReturn:
        return '/assets/images/icon-40-in-return.svg';
      case ComponentState.InService:
        return '/assets/images/icon-40-in-service.svg';
      case ComponentState.InUse:
        return '/assets/images/icon-40-in-use.svg';
    }
  }

  getTitle(): string | Interpolation {
    if (this.component === undefined) {
      return '';
    }

    if (this.component.archived) {
      return 'Archived';
    }

    if (this.solution === SolutionSpace.Humidity) {
      return getHumidityTitle(this.component.componentHealth);
    } else {
      // utilization
      switch (this.component.state) {
        case ComponentState.Archived:
          return 'Archived';
        case ComponentState.Available:
          return 'Available';
        case ComponentState.InReturn:
          return 'In return';
        case ComponentState.InService:
          return 'In service';
        case ComponentState.InUse:
          return 'In use';
      }
    }
  }

  getSolution(): string {
    return this.solution === SolutionSpace.Utilization ? 'utilization' : 'humidity';
  }
}
